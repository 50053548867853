import dayjs from "dayjs";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import vi from "src/localization/languages/vi";

export const resources = {
  vi: vi,
} as const;

i18n
  .use(initReactI18next) // bind react-i18next to the instance
  .init({
    fallbackLng: "vi",
    lng: "vi",
    debug: true,
    ns: Object.keys(resources),
    resources,

    interpolation: {
      escapeValue: false, // not needed for react!!
      format: function (value, format) {
        if (format === "uppercase") {
          return value.toUpperCase();
        }

        if (format === "lowercase") {
          return value.toLowerCase();
        }

        if (dayjs.isDayjs(value)) {
          return value.format(format);
        }

        if (value instanceof Date) {
          return dayjs(value).format(format);
        }

        return value;
      },
    },
  });

export { i18n };
