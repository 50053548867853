import errors from "./errors.json";
import pages from "./pages.json";
import models from "./models";
import user from "./user.json";
import profile from "./profile.json";
import wallet from "./wallet.json";
import logApp from "./logApp.json";
import logMobile from "./logMobile.json";
import common from "./common.json";
import actions from "./actions.json";
import table from "./table.json";
import bannerApp from "./bannerApp.json";
import account from "./account.json";
import category from "./category.json";
import role from "./role.json";
import auth from "./auth.json";
import message from "./message.json";

const vi = {
  errors,
  pages,
  models,
  user,
  profile,
  wallet,
  logApp,
  logMobile,
  common,
  actions,
  table,
  bannerApp,
  account,
  category,
  role,
  auth,
  message,
};
export default vi;
