import { Spin } from "antd";
import React, { FunctionComponent } from "react";
import { useToggle } from "src/core/hooks/useToggle";

export type LoadingContextType = {
  startLoading: () => void;
  stopLoading: () => void;
  state: boolean;
};

export const LoadingContext = React.createContext<
  LoadingContextType | undefined
>(undefined);

export const LoadingProvider: FunctionComponent = ({ children }) => {
  const { state, on: startLoading, off: stopLoading } = useToggle();

  return (
    <LoadingContext.Provider value={{ startLoading, stopLoading, state }}>
      <div id="main-spin-container">
        <Spin spinning={state}>{children}</Spin>
      </div>
    </LoadingContext.Provider>
  );
};
