import { Redirect, Switch } from "react-router";
import { IRoute } from "src/core/models/routes";
import PrivateRoute from "src/core/routes/PrivateRoute";
import { makeId } from "src/core/utilities";

const generateRoutes = (route: IRoute, notFoundPath = "/404") => {
  if (route.redirect) {
    return (
      <Redirect
        key={makeId(12)}
        exact={typeof route.exact === "undefined" ? true : route.exact}
        from={route.path}
        to={route.redirect}
      />
    );
  }

  if (route.routes) {
    const { component: Component, routes, ...nest } = route;
    const childRoutes = routes.map((child) => {
      return generateRoutes(child, notFoundPath);
    });

    return (
      <PrivateRoute
        {...nest}
        key={makeId(12)}
        render={(props) => {
          if (Component) {
            return (
              <Component {...props}>
                <Switch>
                  {childRoutes}
                  <Redirect
                    key={makeId(12)}
                    path="*"
                    to={notFoundPath}
                    exact={false}
                  />
                </Switch>
              </Component>
            );
          }

          return (
            <Switch>
              {childRoutes}
              <Redirect
                key={makeId(12)}
                path="*"
                to={notFoundPath}
                exact={false}
              />
            </Switch>
          );
        }}
      />
    );
  }

  return (
    <PrivateRoute
      {...route}
      key={makeId(12)}
      exact={typeof route.exact === "undefined" ? true : route.exact}
    />
  );
};

export default generateRoutes;
// thanh commit
