export enum ModalTypes {
  Create = "1",
  Read = "2",
  Update = "3",
  Delete = "4",
}

export enum ProvideDataType {
  /**
   * Thông tin cơ bản
   */
  BasicInformation = "basicInformation",
  /**
   * Thông tin nâng cao
   */
  AdvanceInformation = "advanceInformation",
  /**
   * Thông tin mở rộng
   */
  ExtendInformation = "extendInformation",
}
