import env from "src/core/env";
import { Repository } from "src/core/Repository";
import {
  ApproveUserBankAccountRequest,
  UserBankAccountsResponse,
} from "src/models/UserBankAccount";

class UserBankAccountRepository extends Repository {
  protected basePath = "admin/bankAccount";

  constructor() {
    super(env.API_WALLET_URL);
  }

  getUserAccounts(userId: string) {
    return this.request<UserBankAccountsResponse>({
      url: "/getUserBankAccounts/" + userId,
      method: "get",
    });
  }

  approveBankAccount(data: ApproveUserBankAccountRequest) {
    return this.request({
      url: "/approveUserBankAccount",
      method: "POST",
      data,
    });
  }
}

export default new UserBankAccountRepository();
