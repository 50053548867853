import React from "react";
import { Row, Col, Avatar, Typography, Space, Table } from "antd";
import DashboardLayout from "src/layouts/Dashboard";
import { Card } from "src/components";
import { info } from "src/pages/data";
import Images from "src/assets/images";
// import { Tables } from "./table";

interface ProfileContainerProps {
  children?: React.ReactNode;
}

const ContainerProfile = ({ children }: ProfileContainerProps) => {
  // const { children } = props;
  const { Title, Link, Text } = Typography;
  const shadow = { boxShadow: "0 2px 2px rgba(0, 0, 0, 0.25)" };
  // console.log("propss", props.children);
  return (
    <div className="profile">
      <Row>
        <Col span={7} xl={7} lg={24} xs={24} className="flex-center maxheight">
          <Card className="shadow_profile" style={{ width: 300 }}>
            <Avatar className="avatar" src={Images.avatar} />
            <div
              className="flex-center"
              style={{ marginTop: 20, fontSize: 18 }}
            >
              <Link>Thay đổi</Link>
            </div>
          </Card>
        </Col>
        <Col span={16} xl={16} lg={24} xs={24} style={{ marginLeft: 10 }}>
          <Card className="box-shadow">
            <Table
              dataSource={[info]}
              className="table_detail"
              pagination={false}
              // className="shadow_profile"
            >
              <Table.Column title="GNG ID" dataIndex="GngId" />
              <Table.Column align="center" title="Họ tên" dataIndex="Name" />
              <Table.Column
                align="center"
                title="Quyền"
                render={() => (
                  <Typography.Text className="texts">Admin</Typography.Text>
                )}
              />
            </Table>
          </Card>
          <div style={{ marginTop: 20 }}>{children}</div>
        </Col>
      </Row>
    </div>
  );
};
export default ContainerProfile;
