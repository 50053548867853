import { Route, RouteProps } from "react-router";
import usePermissions from "src/core/permission/usePermissions";

interface PrivateRouteProps extends Omit<RouteProps, "path"> {
  path: string;
  permissions?: string | string[];
}

const PrivateRoute = ({
  permissions,
  component: Component,
  render,
  ...props
}: PrivateRouteProps) => {
  const { can, deniedView } = usePermissions();

  return (
    <Route
      {...props}
      render={(renderProps) => {
        if (!can(permissions || [])) {
          return deniedView || <></>;
        }

        if (Component) {
          return <Component {...renderProps} />;
        }

        if (render) {
          return render(renderProps);
        }

        return <></>;
      }}
    />
  );
};

export default PrivateRoute;
