import Images from "src/assets/images";
export const data = [
  {
    key: "1",
    Id: 1,
    GngId: "GNG001",
    Name: "Nguyễn Du",
    Title: "Chiến dịch FB",
    FormTime: "12:30 10/07/2021",
    ToTime: "12:30 10/08/2021",
    Status: true,
    Male: true,
    PhoneNumber: "0123456789",
    Role: "Admin",
    Balance: "789",
    Point: 1200,
    NDT: "17.07.2021",
    Media: "17.07.2021",
    TX9: "17.07.2021",
    ADS: "17.07.2021",
    new: "Hiện tại",
    Facebook: true,
    File: "Quyết định số 01/QĐ/GNG ngày 22/8/2021",
  },
  {
    key: "2",
    Id: 2,
    Point: 350,
    GngId: "GNG002",
    Name: "Nguyễn Thị Thuý Kiều",
    Title: "Kinh tế chia sẻ",
    FormTime: "08:07 10/07/2022",
    ToTime: "01:30 10/08/2023",
    Status: false,
    Male: false,
    PhoneNumber: "0129876543",
    Role: "",
    Balance: "",
    NDT: "",
    Media: "",
    TX9: "",
    ADS: "",
    new: "Đã dùng",
    Facebook: false,
    File: "Quyết định số 01/QĐ/GNG ngày 22/8/2021",
  },
  {
    key: "3",
    Id: 3,
    GngId: "GNG003",
    Name: "Đinh Bằng Sắt",
    Title: "Chiến dịch FB",
    FormTime: "12:30 10/07/2021",
    ToTime: "12:30 10/08/2021",
    Status: false,
    Male: true,
    PhoneNumber: "0124569873",
    Role: "New York No. 1 Lake Park",
    Balance: "789",
    NDT: "17.07.2021",
    Media: "17.07.2021",
    TX9: "17.07.2021",
    ADS: "17.07.2021",
    new: "Tổng",
    Facebook: true,
    Point: 500,
    File: "Quyết định số 01/QĐ/GNG ngày 22/8/2021",
  },
];

export const info = {
  Name: "Thanh Nguyen",
  Phone: "0123456789",
  Male: true,
  avatar: Images.avatar,
  Key: "3",
  Id: 3,
  GngId: "GNG003",
  Title: "Chiến dịch FB",
  FormTime: "12:30 10/07/2021",
  ToTime: "12:30 10/08/2021",
  Status: false,
  PhoneNumber: "0124569873",
  Role: "New York No. 1 Lake Park",
  Balance: "789",
  Point: 350,
  NDT: "17.07.2021",
  Media: "17.07.2021",
  TX9: "17.07.2021",
  ADS: "17.07.2021",
  new: "Tổng",
  Facebook: true,
  File: "Quyết định số 01/QĐ/GNG ngày 22/8/2021",
  Link: "thhp://gianguyen.com",
  Mail: "dev.thanhnd@gmail.com",
};
export const infos = [
  {
    name: "Thanh Nguyen",
    phone: "0123456789",
    male: true,
    avatar: Images.avatar,
    code: "222",
    title: "title",
  },
];
