import { lazy } from "react";
import { useTranslation } from "react-i18next";
import { MenuItem } from "src/models/layout";
import routePaths from "src/routes/paths";
import { CategoryRoute } from "src/routes/categoryRoute";
import {
  UnorderedListOutlined,
  FacebookOutlined,
  LaptopOutlined,
  MobileOutlined,
  UserOutlined,
  StarOutlined,
  WalletOutlined,
  AuditOutlined,
} from "@ant-design/icons";

const ScreenRoutes = (): MenuItem[] => {
  const { t } = useTranslation("pages");
  const Category = CategoryRoute();
  return [
    {
      name: t("user.menu_name"),
      path: routePaths.Screen.Index,
      component: lazy(() => import("src/pages/authenticated/user")),
      icon: <UserOutlined />,
    },
    {
      name: t("wallet.user_wallet.menu_name"),
      path: routePaths.Screen.UserWallet.Index,
      icon: <WalletOutlined />,
      hideChildrenInMenu: false,
      component: lazy(
        () => import("src/pages/authenticated/walletList/userWallet")
      ),
    },
    {
      name: t("wallet.business_wallet.menu_name"),
      path: routePaths.Screen.BusinessWallet.Index,
      icon: <WalletOutlined />,
      hideChildrenInMenu: false,
      component: lazy(
        () => import("src/pages/authenticated/walletList/businessWallet")
      ),
    },
    {
      name: t("banner_app.menu_name"),
      path: routePaths.Screen.BannnerApp.Index,
      component: lazy(() => import("src/pages/authenticated/bannerApp")),
      icon: <FacebookOutlined />,
    },
    {
      name: t("account.menu_name"),
      path: routePaths.Screen.Account.Index,
      component: lazy(() => import("src/pages/authenticated/account")),
      icon: <UnorderedListOutlined />,
    },
    {
      name: t("role.menu_name"),
      path: routePaths.Screen.Role.Index,
      icon: <StarOutlined />,
      component: lazy(() => import("src/pages/authenticated/role")),
    },
    {
      name: t("log_mobile.menu_name"),
      path: routePaths.Screen.LogMobile.Index,
      icon: <MobileOutlined />,
      component: lazy(() => import("src/pages/authenticated/logUserMobile")),
    },
    {
      name: t("log_app.menu_name"),
      path: routePaths.Screen.LogApp.Index,
      icon: <LaptopOutlined />,
      component: lazy(() => import("src/pages/authenticated/logUserAppCMS")),
    },
    {
      name: t("category.menu_name"),
      path: routePaths.Screen.Category.Index,
      hideChildrenInMenu: false,
      icon: <AuditOutlined />,
      routes: Category,
    },
  ];
};
export default ScreenRoutes;
