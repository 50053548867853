const env = {
  APP_NAME: "",
  DEFAULT_PAGE_SIZE: 10,
  API_URL: "https://cms.gianguyen-dev.abcsoft.vn/",
  API_VERSION: 1,
} as ENV;

for (const key in process.env) {
  if (key.indexOf("REACT_APP_") === 0) {
    const newKey = key.replace("REACT_APP_", "") as keyof ENV;
    if (!process.env[newKey]) {
      env[newKey] = process.env[key] as never;
    }
    continue;
  }

  env[key as keyof ENV] = process.env[key] as never;
}

export default env;
