import env from "src/core/env";
import { Repository } from "src/core/Repository";
import {
  CreateCategoryRequest,
  UpdateCategoryProps,
  ListCategoryProps,
  ListCategoryResponse,
} from "src/models/categorys";
import { DetailProps } from "src/models/common";
import { AllLocationResponse } from "src/models/Location";

class CategoryRespository extends Repository {
  protected basePath = "/";

  constructor() {
    super(env.API_URL);
  }

  listCategory(params?: ListCategoryProps) {
    return this.request<ListCategoryResponse>({
      method: "POST",
      url: "commonCategories/getAll",
      data: params,
    });
  }

  getDetailCategory(param: DetailProps) {
    return this.request<null>({
      method: "GET",
      url: `commonCategories/getByIdForApp/${param.id}`,
    });
  }

  createCategory(params: CreateCategoryRequest) {
    return this.request({
      method: "POST",
      url: "commonCategories/create",
      data: params,
    });
  }

  updateCategory(params: UpdateCategoryProps) {
    return this.request({
      method: "POST",
      url: "commonCategories/update",
      data: params,
    });
  }

  deleteCategory(param: DetailProps) {
    return this.request({
      method: "POST",
      url: `commonCategories/delete/${param.id}`,
      data: param,
    });
  }

  getAllLocations() {
    return this.request<AllLocationResponse>({
      method: "GET",
      url: "commonCategories/getAllLocations",
    });
  }
}
export default new CategoryRespository();
