import { lazy } from "react";
import { useTranslation } from "react-i18next";

import { MenuItem } from "src/models/layout";
import routePaths from "src/routes/paths";

export const CategoryRoute = (): MenuItem[] => {
  const { t } = useTranslation("pages");
  return [
    {
      name: t("category.bank.menu_name"),
      path: routePaths.Screen.Category.Bank.index,
      component: lazy(() => import("src/pages/authenticated/category/bank")),
    },
    {
      name: t("category.customer_group.menu_name"),
      path: routePaths.Screen.Category.customerGroup.index,
      component: lazy(
        () => import("src/pages/authenticated/category/customerGroup")
      ),
    },
    {
      name: t("category.job.menu_name"),
      path: routePaths.Screen.Category.job.index,
      component: lazy(() => import("src/pages/authenticated/category/job")),
    },
    {
      name: t("category.hobbies.menu_name"),
      path: routePaths.Screen.Category.hobbies.index,
      component: lazy(() => import("src/pages/authenticated/category/hobbies")),
    },
  ];
};
