import { ofType } from "redux-observable";
import { forkJoin, from, of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { Action } from "src/core/models/redux";
import {
  createReduxObservableModule,
  ReduxObservableModuleEpicProps,
} from "src/core/redux/ReduxObservableModule";
import {
  AllUserRequest,
  ApproveKycRequest,
  DenyKycRequest,
  ToggleServiceSignInRequest,
  UpdateAdminUserRequest,
  UserDetail,
} from "src/models/User";
import { ApproveUserBankAccountRequest } from "src/models/UserBankAccount";
import UserBankAccountRepository from "src/repositories/UserBankAccountRepository";
import UserRepository from "src/repositories/UserRepository";

const userModule = createReduxObservableModule(
  {
    getAll: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<AllUserRequest>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          UserRepository.get(action.payload).pipe(
            map((response) => actions.success(response.data)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),

    toggleServiceSignIn: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<ToggleServiceSignInRequest>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          UserRepository.toggleServiceSignIn(action.payload).pipe(
            map((response) => actions.success(response.data)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),

    getUserDetail: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<string>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          forkJoin([
            UserRepository.getUserAccountInfo(action.payload),
            UserRepository.getUserKycInfo(action.payload),
            UserBankAccountRepository.getUserAccounts(action.payload),
          ]).pipe(
            map((responses) =>
              actions.success({
                accountInfo: responses[0].data.result,
                kycInfo: responses[1].data.result,
                bankAccounts: responses[2].data.result,
              } as UserDetail)
            ),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),

    updateAdminUser: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<UpdateAdminUserRequest>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          UserRepository.updateAdminUser(action.payload).pipe(
            map((response) => actions.success(response.data)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),

    removeUserRole: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<string>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          UserRepository.removeUserRole(action.payload).pipe(
            map((response) => actions.success(response.data)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),

    approveKyc: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<ApproveKycRequest>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          UserRepository.approveKyc(action.payload).pipe(
            map((response) => actions.success(response.data)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),

    denyKyc: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<DenyKycRequest>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          UserRepository.denyKyc(action.payload).pipe(
            map((response) => actions.success(response.data)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),

    approveBankAccounts: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<
      Action<ApproveUserBankAccountRequest[]>
    >) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          from(
            Promise.all(
              action.payload.map((bank) =>
                UserBankAccountRepository.approveBankAccount(bank).toPromise()
              )
            )
          ).pipe(
            map((response) => actions.success(response)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),
  },
  "user"
);

export default userModule;
