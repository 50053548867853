import { useMemo } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { IRoute } from "src/core/models/routes";
import generateRoutes from "src/core/routes/generateRoutes";
import { makeId } from "src/core/utilities";

interface RouterGeneratorProps {
  guestRoutes: IRoute[];
  userRoutes: IRoute[];
  isUser: boolean;
  initialRoutePath?: string;
  initialUserRoutePath: string;
  initialGuestRoutePath: string;
  notFoundPath?: string;
}

const RouterGenerator = ({
  guestRoutes,
  userRoutes,
  isUser,
  initialGuestRoutePath,
  initialUserRoutePath,
  initialRoutePath = "/",
  notFoundPath = "/404",
}: RouterGeneratorProps) => {
  const keys = useMemo(() => {
    return [makeId(12), makeId(12), makeId(12), makeId(12)];
  }, []);

  const generatedGuestRoutes = useMemo(
    () =>
      guestRoutes.map((route) => {
        return generateRoutes(route, notFoundPath);
      }),
    [guestRoutes, notFoundPath]
  );

  const generatedGuestRedirectRoutes = useMemo(
    () =>
      guestRoutes.map((route) => {
        return generateRoutes({
          ...route,
          exact: true,
          redirect: initialUserRoutePath,
        });
      }),
    [guestRoutes, initialUserRoutePath]
  );

  const generatedUserRoutes = useMemo(
    () =>
      userRoutes.map((route) => {
        return generateRoutes(route, notFoundPath);
      }),
    [userRoutes, notFoundPath]
  );

  // Redirect from user routes to initial guest route
  const generatedUserRedirectRoutes = useMemo(
    () =>
      userRoutes.map((route) => {
        return generateRoutes({
          ...route,
          exact: true,
          redirect: initialGuestRoutePath,
        });
      }),
    [userRoutes, initialGuestRoutePath]
  );
  console.log("isUser", isUser);
  return (
    <BrowserRouter>
      <Switch>
        {isUser && (
          <Route key={keys[0]}>
            <Switch>
              {generatedGuestRedirectRoutes}
              {generatedUserRoutes}
              <Redirect key={keys[3]} to={initialUserRoutePath} />
            </Switch>
          </Route>
        )}
        {!isUser && (
          <Route key={keys[1]}>
            <Switch>
              {generatedUserRedirectRoutes}
              {generatedGuestRoutes}
              <Redirect key={keys[4]} to={initialGuestRoutePath} />
            </Switch>
          </Route>
        )}
        <Redirect to={initialRoutePath} exact />
      </Switch>
    </BrowserRouter>
  );
};

export default RouterGenerator;
