import { FormItemProps } from "antd";
import { useTranslation } from "react-i18next";
import { LabeledValue } from "antd/lib/select";
import Form from "../Form";
import Select from "../Select";
import { DataIndex } from "rc-table/lib/interface";

import { isValidElement } from "react";

export interface TableCustomFilterValues<Type extends any = undefined> {
  filter: TableFilterFields<Type>;
  sort: TableSortFields<Type>;
  [key: string]: any;
}

export type TableSortType = "ASC" | "DESC" | null;

export type TableSortFields<Type = undefined> = Type extends undefined
  ? {
      [key: string]: TableSortType;
    }
  : {
      [k in keyof Type]: TableSortType;
    };

export type TableFilterFields<Type = undefined> = Type extends undefined
  ? {
      [key: string]: any;
    }
  : {
      [k in keyof Type]: any;
    };

export interface TableCustomFilterProps {
  customInput?: FormItemProps["children"];
  filters?: LabeledValue[];
  sort?: boolean;
}

const TableCustomFilter = ({
  customInput,
  filters,
  dataIndex,
}: TableCustomFilterProps & { dataIndex?: DataIndex }) => {
  const { t } = useTranslation("common");
  if (!dataIndex) {
    throw new Error("You must provide dataIndex to use customFilter");
  }

  return (
    <>
      {(customInput || filters) && (
        <>
          {typeof customInput === "function" ? (
            <Form.Item noStyle shouldUpdate>
              {(form) => customInput(form)}
            </Form.Item>
          ) : isValidElement(customInput) ? (
            <Form.Item
              name={[
                "filter",
                ...(Array.isArray(dataIndex) ? dataIndex : [dataIndex]),
              ]}
              noStyle
            >
              {customInput}
            </Form.Item>
          ) : (
            <Form.Item
              name={[
                "filter",
                ...(Array.isArray(dataIndex) ? dataIndex : [dataIndex]),
              ]}
              noStyle
            >
              <Select
                placeholder={t("select_placeholder")}
                options={filters}
                className="input-filter w-100"
                allowClear
              />
            </Form.Item>
          )}
        </>
      )}
    </>
  );
};

export default TableCustomFilter;
