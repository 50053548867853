import { message } from "antd";
import { ActionsObservable, combineEpics } from "redux-observable";
import { catchError } from "rxjs/operators";
import { i18n } from "src/localization";
import authModule from "src/redux/modules/auth";
import profileModule from "src/redux/modules/profile";
import categorysModule from "src/redux/modules/categorys";
import categorysBanksModule from "src/redux/modules/categorys/banksCategory";
import categorysCustomerModule from "src/redux/modules/categorys/customerCategory";
import bannerModule from "src/redux/modules/banner";
import dataLookupModule from "src/redux/modules/dataLookup";
import commonCategoriesModule from "src/redux/modules/commonCategories";
import userModule from "src/redux/modules/user";
import roleModule from "src/redux/modules/role";
import logModule from "src/redux/log";
import walletModule from "src/redux/modules/wallet";

export const rootEpic = (
  action$: ActionsObservable<any>,
  store$: any,
  dependencies: any
) =>
  combineEpics(
    ...authModule.epics,
    ...profileModule.epics,
    ...categorysModule.epics,
    ...categorysBanksModule.epics,
    ...categorysCustomerModule.epics,
    ...bannerModule.epics,
    ...dataLookupModule.epics,
    ...commonCategoriesModule.epics,
    ...userModule.epics,
    ...roleModule.epics,
    ...logModule.epics,
    ...walletModule.epics
  )(action$, store$, dependencies).pipe(
    catchError((_, source) => {
      message.error(i18n.t("errors:server_error"));
      return source;
    })
  );
