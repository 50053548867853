import env from "src/core/env";
import { Repository } from "src/core/Repository";
import { ListClientResposne } from "src/models/Client";

class ClientRepository extends Repository {
  protected basePath = "auth/client";

  constructor() {
    super(env.API_AUTH_URL);
  }

  get() {
    return this.request<ListClientResposne>({
      method: "GET",
      url: "/getAll",
    });
  }
}

export default new ClientRepository();
