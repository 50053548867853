const Images = {
  get accessDenied() {
    return require("./images/access_denied.svg").default;
  },
  get error() {
    return require("./images/error.svg").default;
  },
  get notFound() {
    return require("./images/404.svg").default;
  },
  get avatar() {
    return require("./images/image3.png").default;
  },
  get menu() {
    return {
      logo: require("./images/logo-screen.png").default,
      icon_dashboard: require("./images/icon-dashboard.png").default,
      icon_user: require("./images/icon-user.png").default,
      icon_role: require("./images/icon-role.png").default,
      menu_banner: require("./images/menu_banner.png").default,
      menu_account: require("./images/menu-account.png").default,
      menu_logApp: require("./images/menu-logApp.png").default,
      menu_logMobi: require("./images/menu-logMobi.png").default,
      menu_wallet: require("./images/menu-wallet.png").default,
    };
  },
  get auth() {
    return {
      logo: require("./images/logo.png").default,
      background: require("./images/background.png").default,
    };
  },
  get pages() {
    return {
      sort: require("./images/sort.png").default,
      sortAscend: require("./images/sort-ascend.png").default,
      sortDescend: require("./images/sort-descend.png").default,
      edit: require("./images/pen.png").default,
    };
  },
  get table() {
    return {
      icon_sort: require("./images/icon-sort.png").default,
      icon_edit: require("./images/pen.png").default,
      icon_delete: require("./images/trash.png").default,
      icon_view_disable: require("./images/eye-close.png").default,
      icon_view_enable: require("./images/eye-close.png").default,
      icon_view_lock: require("./images/lock-close.png").default,
      icon_view_unlock: require("./images/lock-open.png").default,
      icon_money: require("./images/money.png").default,
    };
  },
  get interests() {
    return {
      icon_camera: require("./images/icon_camera.png").default,
      icon_earth: require("./images/icon_earth.png").default,
      icon_swim: require("./images/icon_swim.png").default,
    };
  },
};

export default Images;
