import { ofType } from "redux-observable";
import { of } from "rxjs";
import { catchError, map, mergeMap, takeUntil } from "rxjs/operators";
import { Action } from "src/core/models/redux";
import {
  createReduxObservableModule,
  ReduxObservableModuleEpicProps,
} from "src/core/redux/ReduxObservableModule";
import routePaths from "src/routes/paths";
import {
  ForgotPasswordSmsRequest,
  ForgotPasswordVerifyRequest,
  LoginRequest,
  LoginResponse,
} from "src/models/auth/Auth";
import { AuthReducer } from "src/models/auth/AuthReducer";
import { AuthState } from "src/models/auth/AuthState";
import AuthRepository from "src/repositories/AuthRepository";

const defaultState: AuthReducer = {
  token: null,
  account: undefined,
};

const authModule = createReduxObservableModule(
  {
    login: {
      epic: ({
        actions$,
        actionTypes,
        actions,
      }: ReduxObservableModuleEpicProps<Action<LoginRequest>>) =>
        actions$.pipe(
          ofType(actionTypes.start),
          mergeMap((action) =>
            AuthRepository.login(action.payload).pipe(
              map((response) => actions.success(response.data)),
              catchError((error) => {
                return of(actions.failed(error));
              }),
              takeUntil(actions$.pipe(ofType(actionTypes.cancelled)))
            )
          )
        ),

      reducers: {
        processing: (state) => {
          return {
            ...state,
            state: AuthState.loggingIn,
            data: {
              token: null,
              account: null,
            },
          };
        },

        success: (state, payload: LoginResponse) => {
          return {
            ...state,
            state: AuthState.loggedIn,
            data: {
              token: payload.result.accessToken,
              refreshToken: payload.result.refreshToken,
              account: payload.result.userInfo,
            },
          };
        },

        failed: (state, error) => {
          return {
            ...state,
            state: AuthState.loginFailed,
            data: {
              token: null,
              account: null,
            },
            error,
          };
        },
      },
    },

    logout: {
      reducers: {
        processing: (state) => {
          return {
            ...state,
            state: AuthState.loggedOut,
            data: {
              token: null,
              account: null,
            },
          };
        },
      },
    },

    forgotPasswordSms: {
      epic: ({
        actions$,
        actionTypes,
        actions,
      }: ReduxObservableModuleEpicProps<Action<ForgotPasswordSmsRequest>>) =>
        actions$.pipe(
          ofType(actionTypes.start),
          mergeMap((action) =>
            AuthRepository.forgotPasswordSms(action.payload).pipe(
              map((reponse) => actions.success(reponse.data)),
              catchError((error) => {
                return of(actions.failed(error));
              }),
              takeUntil(actions$.pipe(ofType(actionTypes.failed)))
            )
          )
        ),
    },
    forgotPasswordVerify: {
      epic: ({
        actions$,
        actionTypes,
        actions,
      }: ReduxObservableModuleEpicProps<Action<ForgotPasswordVerifyRequest>>) =>
        actions$.pipe(
          ofType(actionTypes.start),
          mergeMap((action) =>
            AuthRepository.forgotPasswordVerify(action.payload).pipe(
              map((response) => {
                window.location.href = routePaths.Auth.Login;
                actions.success(response.data);
              }),
              catchError((error) => {
                return of(actions.failed(error));
              }),
              takeUntil(actions$.pipe(ofType(actionTypes.failed)))
            )
          )
        ),
    },
  },
  "auth",
  defaultState
);

export default authModule;
