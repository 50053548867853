import { Row as AntRow, RowProps as AntRowProps } from "antd";
import { Gutter } from "antd/lib/grid/row";
import { useMemo } from "react";

type RowProps = AntRowProps & {
  spaceRow?: boolean;
};

const Row = ({ spaceRow = true, ...props }: RowProps) => {
  const gutter: Gutter | [Gutter, Gutter] = useMemo(
    () => (props.gutter ? props.gutter : spaceRow ? [16, 16] : 16),
    [spaceRow, props.gutter]
  );

  return <AntRow {...props} gutter={gutter} />;
};

export default Row;
