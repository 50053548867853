import { ofType } from "redux-observable";
import { of } from "rxjs";
import { catchError, map, mergeMap, takeUntil } from "rxjs/operators";
import {
  createReduxObservableModule,
  ReduxObservableModuleEpicProps,
} from "src/core/redux/ReduxObservableModule";
import { Action } from "src/core/models/redux";
import ProfileRespository from "src/repositories/ProfileRespository";

const ProfileModule = createReduxObservableModule(
  {
    getProfileModule: {
      epic: ({
        actions$,
        actionTypes,
        actions,
      }: ReduxObservableModuleEpicProps<Action<any>>) =>
        actions$.pipe(
          ofType(actionTypes.start),
          mergeMap(() =>
            ProfileRespository.getProfile().pipe(
              map((response) => actions.success(response.data)),
              catchError((error) => {
                return of(actions.failed(error));
              }),
              takeUntil(actions$.pipe(ofType(actionTypes.cancelled)))
            )
          )
        ),
    },
  },
  "PROFILE"
);
export default ProfileModule;
