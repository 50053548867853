import env from "src/core/env";
import { Repository } from "src/core/Repository";
import {
  AllUserRequest,
  AllUserResponse,
  ApproveKycRequest,
  DenyKycRequest,
  ToggleServiceSignInRequest,
  UpdateAdminUserRequest,
  UserAccountInfoResponse,
  UserKycResponse,
} from "src/models/User";

class UserRepository extends Repository {
  protected basePath = "auth/admin";
  constructor() {
    super(env.API_AUTH_URL);
  }

  get(data: AllUserRequest) {
    return this.request<AllUserResponse>({
      method: "POST",
      url: "/getAllUsers",
      data,
    });
  }

  toggleServiceSignIn(data: ToggleServiceSignInRequest) {
    return this.request<undefined>({
      method: "POST",
      url: "/switchOnOffClientSignIn",
      data,
    });
  }

  getUserAccountInfo(id: string) {
    return this.request<UserAccountInfoResponse>({
      url: "/getUserAccountInfo/" + id,
      method: "get",
    });
  }

  getUserKycInfo(id: string) {
    return this.request<UserKycResponse>({
      url: "/getUserProfile/" + id,
      method: "get",
    });
  }

  approveKyc(data: ApproveKycRequest) {
    return this.request({
      url: "/approveKYC",
      method: "POST",
      data,
    });
  }

  denyKyc(data: DenyKycRequest) {
    return this.request({
      url: "/denykyc",
      method: "POST",
      data,
    });
  }

  updateAdminUser(data: UpdateAdminUserRequest) {
    return this.request({
      url: "/updateAdminUser",
      method: "POST",
      data,
    });
  }

  removeUserRole(id: string) {
    return this.request({
      url: "/removeRoleFromUser/" + id,
      method: "PUT",
    });
  }
}

export default new UserRepository();
