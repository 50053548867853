import { DownOutlined } from "@ant-design/icons";
import { Popover, PopoverProps, Col, Space } from "antd";
import Checkbox, { CheckboxGroupProps } from "antd/lib/checkbox";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import Button, { ButtonProps } from "src/components/Button";
import { useDidUpdate } from "src/core/hooks";
import Row from "./Row";

type CheckboxPopoverProps = CheckboxGroupProps & {
  popoverProps?: PopoverProps;
  buttonProps?: ButtonProps;
  clearable?: boolean;
  haveToSubmit?: boolean;
  onCancel?: () => void;
};

const CheckboxPopover = ({
  popoverProps,
  buttonProps,
  children,
  clearable = false,
  haveToSubmit = false,
  onCancel,
  ...props
}: CheckboxPopoverProps) => {
  const { t } = useTranslation("actions");
  const [value, setValue] = useState(props.value);

  useDidUpdate(() => {
    if (props.value !== value) {
      setValue(props.value);
    }
  }, [props.value]);

  const handleChange = useCallback(
    (newValue: CheckboxValueType[]) => {
      setValue(newValue);
      !haveToSubmit && props.onChange && props.onChange(newValue);
    },
    [props.onChange, haveToSubmit]
  );

  const handleSubmit = useCallback(() => {
    props.onChange && props.onChange(value || []);
  }, [props.onChange, value]);

  const clearValue = useCallback(() => {
    setValue(undefined);
  }, []);

  return (
    <Popover
      trigger="click"
      placement="bottomRight"
      {...popoverProps}
      content={
        <>
          <Checkbox.Group
            {...props}
            options={undefined}
            value={value}
            onChange={handleChange}
          >
            <Row gutter={[16, 16]} className="px-3">
              {props.options && props.options.length
                ? props.options.map((option, index) => (
                    <Col xs={12} md={12} key={`${index}`}>
                      <Checkbox {...option} key={index}>
                        {typeof option === "string" ? option : option.label}
                      </Checkbox>
                    </Col>
                  ))
                : popoverProps?.content}
            </Row>
          </Checkbox.Group>
          <Space
            direction="horizontal"
            className="d-flex justify-content-end pt-4 pb-2 px-3"
          >
            {clearable && (
              <Button onClick={clearValue} danger>
                {t("clear")}
              </Button>
            )}
            {haveToSubmit && (
              <Button
                htmlType="button"
                type="primary"
                className="px-4 border-0"
                style={{ backgroundColor: "var(--primary-color)" }}
                onClick={handleSubmit}
              >
                {t("apply")}
              </Button>
            )}
            <Button
              className="px-4"
              style={{
                borderColor: "var(--primary-color)",
                color: "var(--primary-color)",
              }}
              onClick={onCancel}
            >
              {t("close")}
            </Button>
          </Space>
        </>
      }
    >
      <Button
        icon={<DownOutlined style={{ fontSize: "12px", height: "12px" }} />}
        iconRight
        {...buttonProps}
      >
        {children}
      </Button>
    </Popover>
  );
};

export default CheckboxPopover;
