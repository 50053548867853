import React from "react";
import { connect } from "react-redux";
import { Col, Avatar, Typography, Button, Space, Input, Form } from "antd";
import { Row, Table, Card } from "src/components";
import ContainerProfile from "./component/container";

const ChangePassword = () => {
  const { Title, Text } = Typography;
  const { Item } = Form;
  return (
    <ContainerProfile>
      <Card
        className="box-shadow mb-20 color_title"
        title="Đổi mật khẩu"
        style={{ padding: 0 }}
      >
        <Table
          className="table_detail"
          dataSource={[{ id: 1 }]}
          pagination={false}
          style={{ padding: 0, marginTop: 5 }}
          formWrap={false}
        >
          <Table.Column
            // align="center"
            width={120}
            title="Mật khẩu đang dùng"
            render={() => <Input.Password />}
          />
          <Table.Column
            align="center"
            width={120}
            title="Mật khẩu mới"
            render={() => <Input.Password />}
          />
          <Table.Column
            align="center"
            width={120}
            title="Nhập lại mật khẩu"
            render={() => <Input.Password />}
          />
        </Table>
      </Card>
    </ContainerProfile>
  );
};
const mapStateToProps = () => ({});
const mapDispatchToProps = () => ({});
export default ChangePassword;
//  <Card
//    className="box-shadow mb-20 color_title"
//    title="Đổi mật khẩu"
//    style={{ padding: 0 }}
//  >
//    <Table
//      className="table_detail"
//      dataSource={[{ id: 1 }]}
//      pagination={false}
//      style={{ padding: 0, marginTop: 5 }}
//      formWrap={false}
//    >
//      <Table.Column
//        // align="center"
//        width={120}
//        title="Mật khẩu đang dùng"
//        render={() => <Input.Password />}
//      />
//      <Table.Column
//        align="center"
//        width={120}
//        title="Mật khẩu mới"
//        render={() => <Input.Password />}
//      />
//      <Table.Column
//        align="center"
//        width={120}
//        title="Nhập lại mật khẩu"
//        render={() => <Input.Password />}
//      />
//    </Table>
//  </Card>;
