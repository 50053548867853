import { BaseResponse } from "src/models/Base";

export enum LocationType {
  Province = 1,
  District = 2,
  Ward = 3,
}

export interface Location {
  id: string;
  code: string;
  title: string;
  note: string;
  priority: number;
  status: boolean;
  isDefault: boolean;
  type: LocationType;
  parentCode: string;
}

export type AllLocationResponse = BaseResponse<Location[]>;
