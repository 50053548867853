import env from "src/core/env";
import { Repository } from "src/core/Repository";
import {
  ListBusinessWalletRequest,
  ListBusinessWalletResponse,
  ListTransactionHistoryResponse,
  ListUserWalletRequest,
  ListUserWalletResponse,
} from "src/models/Wallet";

class WalletRepository extends Repository {
  protected basePath = "admin";

  constructor() {
    super(env.API_WALLET_URL);
  }

  getAllUserWallet(data: ListUserWalletRequest) {
    return this.request<ListUserWalletResponse>({
      url: "/wallet/getAllUserWallet",
      method: "post",
      data,
    });
  }

  getAllBusinessWallet(data: ListBusinessWalletRequest) {
    return this.request<ListBusinessWalletResponse>({
      url: "/getAllBusinessWallet",
      method: "post",
      data,
    });
  }

  getBusinessWalletDetail(id: string) {
    return this.request({
      url: "/wallet/getBusinessWalletDetails/" + id,
    });
  }

  getWalletBalanceHistory(walletId: string) {
    return this.request<ListTransactionHistoryResponse>({
      url: "/wallet/getWalletBalanceHistory",
      method: "POST",
      data: { walletId },
    });
  }
}

export default new WalletRepository();
