import { lazy } from "react";
import { MenuItem } from "src/models/layout";
import routePaths from "src/routes/paths";
import useScreenRoutes from "src/routes/screenRoutes";
import DashboardLayout from "src/layouts/Dashboard";

export default (): MenuItem[] => {
  const screenRoutes = useScreenRoutes();
  return [
    {
      path: routePaths.Screen.Index,
      exact: false,
      component: (props: any) => (
        <DashboardLayout {...props} routes={screenRoutes} />
      ),
      routes: screenRoutes,
    },
    {
      path: "/404",
      exact: true,
      component: lazy(() => import("src/pages/NotFound")),
    },
  ];
};

// export const guestRoutes: MenuItem[] = [];
