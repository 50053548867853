import configureStore from "./configureStore";
import { rootReducer } from "./modules/rootReducer";

const { store, persistor } = configureStore();

export { persistor };

export type RootReducer = ReturnType<typeof rootReducer>;

export default store;
