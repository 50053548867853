import React from "react";

interface Props {
  src?: string;
  width?: number;
}

const IconImg = ({ src, width }: Props) => {
  return (
    <img
      src={src}
      style={{
        maxWidth: width,
        margin: "0 auto",
      }}
    />
  );
};

export default IconImg;
