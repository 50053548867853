import env from "src/core/env";
import { Repository } from "src/core/Repository";
import { LogRequest, LogResponse } from "src/models/Log";

class LogRepository extends Repository {
  protected basePath = "";

  constructor() {
    super(env.API_LOG_URL);
  }

  get(data: LogRequest) {
    return this.request<LogResponse>({
      url: "/action-log/search",
      method: "POST",
      data,
    });
  }

  delete(id: string) {
    return this.request({
      url: "/action-log/delete/" + id,
      method: "DELETE",
    });
  }
}

export default new LogRepository();
