import { useMemo } from "react";
import { Card as AntCard, CardProps as AntCardProps } from "antd";

export interface CardProps extends AntCardProps {
  shadow?: boolean;
  noBorder?: boolean;
  noPaddingTop?: boolean;
  noPaddingBottom?: boolean;
}

const Card = ({
  bordered = false,
  shadow = true,
  noPaddingTop = false,
  noPaddingBottom = false,
  noBorder,
  ...props
}: CardProps) => {
  const className = useMemo(() => {
    let className = props.className ? props.className : "";
    if (noBorder) {
      className += " no-border";
    }

    if (noPaddingTop) {
      className += " no-padding-top";
    }

    if (noPaddingBottom) {
      className += " no-padding-bottom";
    }

    return className;
  }, [noBorder, props.className]);
  return (
    <AntCard
      {...props}
      className={`${className || ""}${shadow ? " ant-card-shadow" : ""}`}
      bordered={bordered}
    />
  );
};

export default Card;
