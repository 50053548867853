import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootReducer } from "src/redux";
import commonCategoriesModule from "src/redux/modules/commonCategories";

export default function useCommconCategories() {
  const dispatch = useDispatch();

  const categories = useSelector(
    (state: RootReducer) => state.commonCategories.data
  );

  const getAllCommonCategories = useCallback(() => {
    dispatch(commonCategoriesModule.actions.getAll.start());
  }, []);

  return {
    getAllCommonCategories,
    ...categories,
  };
}
