import {
  Avatar,
  Col,
  Typography,
  Drawer,
  Layout,
  List,
  Dropdown,
  Menu,
  Button,
} from "antd";
import { useState } from "react";
import { DownOutlined, WarningOutlined, BellOutlined } from "@ant-design/icons";
import CustomModal from "src/components/View/Modal";
import { Row } from "src/components";
import { useHistory } from "react-router-dom";
import routePaths from "src/routes/paths";
import "src/styles/layouts/hearderStyle.less";
import { info } from "src/pages/data";
import { useTranslation } from "react-i18next";
import { useDidUpdate, useMount, useToggle } from "src/core/hooks";
import { ModalTypes } from "src/models/Modal";
import ChangePassword from "src/layouts/Dashboard/profile/changePassword";
import Info from "src/layouts/Dashboard/profile/info";

const { Header } = Layout;

interface props {
  // showModal: any;
}

const LayoutHeader = () => {
  const { t } = useTranslation("profile");

  const [type, setType] = useState<ModalTypes>();
  const { state: visible, on: onVisible, off: handleCancel } = useToggle();

  const data = [
    {
      title: t("info.index"),
      id: 1,
    },
    {
      title: t("change_password.index"),
      id: 2,
    },
    {
      title: t("logout.index"),
      id: 3,
    },
  ];

  const history = useHistory();
  // const [showDra, setVisible] = useState(false);

  const onProfile = (values: any) => {
    console.log("hihihi", values);
    switch (values.id) {
      case 1:
        onVisible();
        setType(ModalTypes.Read);

        break;
      case 2:
        setType(ModalTypes.Update);
        onVisible();
        break;
      case 3:
        localStorage.clear();
        window.location.href = routePaths.Auth.Index;
        break;
    }
  };

  return (
    <Header className="site-layout-background">
      <Row
        justify="space-between"
        className="align-items-center header-row-content"
      >
        <Col>
          <div className="d-flex flex-row justify-content-center align-items-center"></div>
        </Col>
        <Col>
          <Row>
            <Col>
              <WarningOutlined style={{ color: "#EE7B00", fontSize: 20 }} />
            </Col>
            <Col>
              <BellOutlined style={{ color: "#EE7B00", fontSize: 20 }} />
            </Col>
            <Col>
              <Avatar size={30} src={info.avatar} />
            </Col>
            <Col className="d-flex flex-row justify-content-center align-items-center cursor-pointer">
              <Dropdown
                overlay={
                  <Menu>
                    <>
                      {data.map((item: any, index: number) => (
                        <Menu.Item
                          key={index}
                          style={{ minWidth: 100 }}
                          onClick={() => onProfile(item)}
                        >
                          {item.title}
                        </Menu.Item>
                      ))}
                    </>
                  </Menu>
                }
                placement="bottomCenter"
                arrow
              >
                <Button style={{ border: "0px" }}>
                  <Typography.Text style={{ fontWeight: 600, fontSize: 16 }}>
                    {info.Name}
                  </Typography.Text>
                  <DownOutlined style={{ marginLeft: 5 }} />
                </Button>
              </Dropdown>
            </Col>
          </Row>
        </Col>
      </Row>
      <CustomModal
        visible={visible}
        title={
          type === ModalTypes.Read
            ? t("info.index")
            : t("change_password.index")
        }
        type={type}
        handleCancel={() => {
          handleCancel();
        }}
        // handleSubmit={form.submit}
        className="modal-customize-md"
      >
        {type === ModalTypes.Read ? <Info /> : <ChangePassword />}
      </CustomModal>
    </Header>
  );
};

export default LayoutHeader;
