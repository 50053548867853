import { ofType } from "redux-observable";
import { of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { Action } from "src/core/models/redux";
import {
  createReduxObservableModule,
  ReduxObservableModuleEpicProps,
} from "src/core/redux/ReduxObservableModule";
import { ListRoleRequest, SingleRoleRequest } from "src/models/Role";
import RoleRepository from "src/repositories/RoleRepository";

const roleModule = createReduxObservableModule(
  {
    getAllPermissions: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<undefined>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap(() =>
          RoleRepository.getAllPermissions().pipe(
            map((response) => actions.success(response.data)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),

    getAll: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<ListRoleRequest>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          RoleRepository.get(action.payload).pipe(
            map((response) => actions.success(response.data)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),

    create: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<SingleRoleRequest>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          RoleRepository.create(action.payload).pipe(
            map((response) => actions.success(response.data)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),

    update: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<SingleRoleRequest>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          RoleRepository.updateOne(action.payload).pipe(
            map((response) => actions.success(response.data)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),

    delete: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<number>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          RoleRepository.delete(action.payload).pipe(
            map((response) => actions.success(response.data)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),
  },
  "role"
);

export default roleModule;
