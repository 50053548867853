import env from "src/core/env";
import { Repository } from "src/core/Repository";
import { ListServiceTagResponse } from "src/models/ServiceTag";

class ServiceTagRepository extends Repository {
  protected basePath = "/auth/admin";

  constructor() {
    super(env.API_AUTH_URL);
  }

  get() {
    return this.request<ListServiceTagResponse>({
      method: "GET",
      url: "/getAllActiveServiceTags",
    });
  }
}

export default new ServiceTagRepository();
