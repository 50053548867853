import { ofType } from "redux-observable";
import { forkJoin, of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { Action } from "src/core/models/redux";
import {
  createReduxObservableModule,
  ReduxObservableModuleEpicProps,
} from "src/core/redux/ReduxObservableModule";
import categoryCommon from "src/repositories/categoryCommon";
import CategoryBanksRespository from "src/repositories/categoryBanks";
import categoryCustomerGroup from "src/repositories/categoryCustomerGroup";
import { Category, CategoryType } from "src/models/categorys";
import { Client } from "src/models/Client";
import ClientRepository from "src/repositories/ClientRepository";
import { ServiceTag } from "src/models/ServiceTag";
import ServiceTagRepository from "src/repositories/ServiceTagRepository";
import { Location, LocationType } from "src/models/Location";

interface CategoryData<T> {
  mapped: {
    [key: string]: T;
  };
  list: T[];
}

function listToCategoryData<T extends { id: string | number }>(
  data: T[]
): CategoryData<T> {
  const result: CategoryData<T> = {
    mapped: {},
    list: [],
  };

  result.mapped = data.reduce<{ [key: string]: T }>((previous, d) => {
    const key = d.id.toString();
    if (!(key in previous)) {
      previous[key] = d;
    }

    return previous;
  }, {});

  result.list = data;

  return result;
}

export interface CommonCategoriesReducer {
  jobs: CategoryData<Category>;
  hobbies: CategoryData<Category>;
  denyReasons: CategoryData<Category>;
  customerGroups: CategoryData<Category>;
  banks: CategoryData<Category>;
  clients: CategoryData<Client>;
  serviceTags: CategoryData<ServiceTag>;
  provinces: CategoryData<Location>;
  districts: CategoryData<Location>;
  wards: CategoryData<Location>;
}

const defaultState: CommonCategoriesReducer = {
  jobs: {
    mapped: {},
    list: [],
  },
  hobbies: {
    mapped: {},
    list: [],
  },
  denyReasons: {
    mapped: {},
    list: [],
  },
  customerGroups: {
    mapped: {},
    list: [],
  },
  banks: {
    mapped: {},
    list: [],
  },
  clients: {
    mapped: {},
    list: [],
  },
  serviceTags: {
    mapped: {},
    list: [],
  },
  provinces: {
    mapped: {},
    list: [],
  },
  districts: {
    mapped: {},
    list: [],
  },
  wards: {
    mapped: {},
    list: [],
  },
};

const commonCategoriesModule = createReduxObservableModule(
  {
    getAll: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<undefined>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap(() =>
          forkJoin([
            categoryCommon.listCategory({
              page: 1,
              pageLength: 100000,
            }),
            CategoryBanksRespository.listCategoryBanks({
              page: 1,
              pageLength: 100000,
            }),
            categoryCustomerGroup.listCategoryCustomer({
              page: 1,
              pageLength: 100000,
            }),
            ClientRepository.get(),
            ServiceTagRepository.get(),
            categoryCommon.getAllLocations(),
          ]).pipe(
            map((responses) => {
              return actions.success({
                jobs: listToCategoryData(
                  responses[0].data.result.data.filter(
                    (cate) => cate.type === CategoryType.Job
                  )
                ),
                hobbies: listToCategoryData(
                  responses[0].data.result.data.filter(
                    (cate) => cate.type === CategoryType.Hobby
                  )
                ),
                denyReasons: listToCategoryData(
                  responses[0].data.result.data.filter(
                    (cate) => cate.type === CategoryType.DenyReason
                  )
                ),
                banks: listToCategoryData(responses[1].data.result.data),
                customerGroups: listToCategoryData(
                  responses[2].data.result.data
                ),
                clients: listToCategoryData(responses[3].data.result),
                serviceTags: listToCategoryData(responses[4].data.result),
                provinces: listToCategoryData(
                  responses[5].data.result.filter(
                    (location) => location.type === LocationType.Province
                  )
                ),
                districts: listToCategoryData(
                  responses[5].data.result.filter(
                    (location) => location.type === LocationType.District
                  )
                ),
                wards: listToCategoryData(
                  responses[5].data.result.filter(
                    (location) => location.type === LocationType.Ward
                  )
                ),
              } as CommonCategoriesReducer);
            }),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),
  },
  "commonCategories",
  defaultState
);

// Will be called only once in app root
export default commonCategoriesModule;
