import { catchError, map, mergeMap, takeUntil } from "rxjs/operators";
import { ofType } from "redux-observable";
import {
  createReduxObservableModule,
  ReduxObservableModuleEpicProps,
} from "src/core/redux/ReduxObservableModule";
import { of } from "rxjs";
import { Action } from "src/core/models/redux";
import bannerRepository from "src/repositories/bannerRepository";
import { GetListBannerRequest, creacteBannerProps } from "src/models/banner";
import { DetailProps } from "src/models/common";

const bannerModule = createReduxObservableModule(
  {
    listBanner: {
      epic: ({
        actions$,
        actionTypes,
        actions,
      }: ReduxObservableModuleEpicProps<Action<GetListBannerRequest>>) =>
        actions$.pipe(
          ofType(actionTypes.start),
          mergeMap((action) =>
            bannerRepository.listBanner(action.payload).pipe(
              map((response) => actions.success(response.data)),
              catchError((error) => {
                return of(actions.failed(error));
              }),
              takeUntil(actions$.pipe(ofType(actionTypes.cancelled)))
            )
          )
        ),
    },

    createBanner: {
      epic: ({
        actions$,
        actionTypes,
        actions,
      }: ReduxObservableModuleEpicProps<Action<creacteBannerProps>>) =>
        actions$.pipe(
          ofType(actionTypes.start),
          mergeMap((action) =>
            bannerRepository.createBanner(action.payload).pipe(
              map((response) => actions.success(response.data)),
              catchError((error) => {
                return of(actions.failed(error));
              }),
              takeUntil(actions$.pipe(ofType(actionTypes.cancelled)))
            )
          )
        ),
    },

    updateBanner: {
      epic: ({
        actions$,
        actionTypes,
        actions,
      }: ReduxObservableModuleEpicProps<Action<creacteBannerProps>>) =>
        actions$.pipe(
          ofType(actionTypes.start),
          mergeMap((action) =>
            bannerRepository.updateBanner(action.payload).pipe(
              map((response) => actions.success(response.data)),
              catchError((error) => {
                return of(actions.failed(error));
              }),
              takeUntil(actions$.pipe(ofType(actionTypes.cancelled)))
            )
          )
        ),
    },

    updateBannerStatus: {
      epic: ({
        actions$,
        actionTypes,
        actions,
      }: ReduxObservableModuleEpicProps<Action<creacteBannerProps>>) =>
        actions$.pipe(
          ofType(actionTypes.start),
          mergeMap((action) =>
            bannerRepository.updateBanner(action.payload).pipe(
              map((response) => actions.success(response.data)),
              catchError((error) => {
                return of(actions.failed(error));
              }),
              takeUntil(actions$.pipe(ofType(actionTypes.cancelled)))
            )
          )
        ),
    },

    detailBanner: {
      epic: ({
        actions$,
        actionTypes,
        actions,
      }: ReduxObservableModuleEpicProps<Action<DetailProps>>) =>
        actions$.pipe(
          ofType(actionTypes.start),
          mergeMap((action) =>
            bannerRepository.detailBanner(action.payload).pipe(
              map((response) => actions.success(response.data)),
              catchError((error) => {
                return of(actions.failed(error));
              }),
              takeUntil(actions$.pipe(ofType(actionTypes.cancelled)))
            )
          )
        ),
    },

    deleteBanner: {
      epic: ({
        actions$,
        actionTypes,
        actions,
      }: ReduxObservableModuleEpicProps<Action<DetailProps>>) =>
        actions$.pipe(
          ofType(actionTypes.start),
          mergeMap((action) =>
            bannerRepository.deleteBanner(action.payload).pipe(
              map((response) => actions.success(response.data)),
              catchError((error) => {
                return of(actions.failed(error));
              }),
              takeUntil(actions$.pipe(ofType(actionTypes.cancelled)))
            )
          )
        ),
    },
  },
  "BANNER"
);

export default bannerModule;
