import React from "react";
import { Card, Table } from "src/components";
import { info } from "src/pages/data";
import ContainerProfile from "./component/container";

const Profile = () => {
  return (
    <ContainerProfile>
      <Card
        className="box-shadow mb-20 color_title"
        title="Đổi mật khẩu"
        style={{ padding: 0 }}
      >
        <Table
          className="table_detail"
          dataSource={[info]}
          pagination={false}
          style={{ padding: 0, marginTop: 5 }}
          formWrap={false}
        >
          <Table.Column
            align="center"
            dataIndex="Name"
            width={120}
            title="Họ tên"
          />
          <Table.Column
            dataIndex="Phone"
            align="center"
            width={120}
            title="Điện thoại"
          />
          <Table.Column
            dataIndex="Mail"
            align="center"
            width={120}
            title="Email"
          />
        </Table>
      </Card>
    </ContainerProfile>
  );
};
const mapStateToProps = () => ({});
const mapDispatchToProps = () => ({});
export default Profile;
