import env from "src/core/env";
import { AxiosRequestConfig } from "axios";
import { Api } from "src/core/api";

export abstract class Repository<
  Request = any,
  Response = any,
  ListRequest = any,
  ListResponse = any
> {
  protected basePath = "";
  protected apiUrl: string;
  protected api: Api;

  constructor(apiUrl?: string, tokenType?: TokenTypes | null) {
    this.apiUrl = apiUrl || env.API_URL;
    this.api = new Api(this.apiUrl);
    if (typeof tokenType !== "undefined") {
      this.api.setAuthorizationTokenType(tokenType);
    }
  }

  public create(data: Request) {
    return this.api.post<Response>(this.basePath, data);
  }

  public update(id: string | number, data: Request) {
    return this.api.put<Response>(this.basePath + "?id=" + id, data);
  }

  public multipartCreate(data: Request) {
    return this.api.multipartPost<Response>(this.basePath, data);
  }

  public multipartUpdate(data: Request) {
    return this.api.multipartPut<Response>(this.basePath, data);
  }

  public getOne(id: number | string) {
    return this.api.get<Response>(this.basePath + "?id=" + id);
  }

  public get(params?: ListRequest) {
    return this.api.get<ListResponse>(this.basePath, params);
  }

  public delete(id: number | string) {
    return this.api.delete(this.basePath + "?id=" + id);
  }

  public request<Response = any>(config: AxiosRequestConfig) {
    return this.api.request<Response>({
      ...config,
      url: this.basePath + config.url,
    });
  }
}
