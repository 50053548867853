import { Form as AntForm, FormProps as AntFormProps } from "antd";
import { useEffect } from "react";

export interface FormProps<Values = any> extends AntFormProps<Values> {
  enableReinitialize?: boolean;
}

const Form = function <Values = any>({
  enableReinitialize,
  ...props
}: FormProps<Values>) {
  const [form] = AntForm.useForm(props.form);

  useEffect(() => {
    if (enableReinitialize) {
      setTimeout(() => {
        form.resetFields();
      }, 200);
    }
  }, [props.initialValues]);

  return <AntForm {...props} form={form} />;
};

Form.Item = AntForm.Item;
Form.List = AntForm.List;
Form.Provider = AntForm.Provider;
Form.ErrorList = AntForm.ErrorList;
Form.useForm = AntForm.useForm;

export default Form;
