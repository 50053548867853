import {
  Table as AntTable,
  TableColumnProps,
  TableProps as AntTableProps,
} from "antd";
import { ColumnGroupProps } from "antd/lib/table/ColumnGroup";
import { mapValues } from "lodash";
import { convertChildrenToColumns } from "rc-table/lib/hooks/useColumns";
import { useMemo, useState, useEffect } from "react";
import { Column } from "src/components/Table/Column";
import Form from "../Form";
import { TableCustomFilterValues } from "./CustomFilter";
import { transformColumns } from "./customFilterHelpers";

export type TableProps<RecordType> = Omit<
  AntTableProps<RecordType>,
  "columns"
> & {
  headerWhite?: boolean;
  noBorder?: boolean;
  withBorder?: boolean;
  striped?: boolean;
  columns?: TableColumnProps<RecordType>[];
  onCustomFilterChange?: (values: TableCustomFilterValues) => void;
  customFilterValues?: TableCustomFilterValues;
  formWrap?: boolean;
};

interface Table<RecordType> {
  (props: TableProps<RecordType>): JSX.Element;

  Column: TableColumnProps<RecordType>;
  ColumnGroup: ColumnGroupProps<RecordType>;
}

function Table<RecordType extends object = any>({
  noBorder,
  headerWhite,
  withBorder,
  striped,
  onCustomFilterChange,
  children,
  customFilterValues,
  formWrap = true,
  columns,
  ...props
}: TableProps<RecordType>) {
  const className = useMemo(() => {
    let className = props.className ? props.className : "";
    if (noBorder) {
      className += " no-border";
    }

    if (headerWhite) {
      className += " header-white";
    }

    if (withBorder) {
      className += " with-border";
    }
    if (striped) {
      className += " striped";
    }
    return className;
  }, [noBorder, headerWhite, props.className, striped]);

  // Handle filter
  const [queryRequest, setQueryRequest] = useState<TableCustomFilterValues>();
  const [form] = Form.useForm<TableCustomFilterValues>();

  const mergedColumns = useMemo(() => {
    return transformColumns(
      columns ?? (convertChildrenToColumns<RecordType>(children) as any)
    );
  }, [columns, children]);

  const handleCustomFilterChange = (
    changes: any,
    values: TableCustomFilterValues
  ) => {
    form.setFieldsValue({
      ...values,
      sort: {
        ...mapValues(values.sort, () => null),
        ...changes.sort,
      },
    });

    setQueryRequest((old) => ({ ...values, sort: changes.sort ?? old?.sort }));
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (queryRequest) {
        onCustomFilterChange && onCustomFilterChange(queryRequest);
      }
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [queryRequest]);

  if (formWrap) {
    return (
      <Form
        form={form}
        initialValues={customFilterValues}
        onValuesChange={handleCustomFilterChange}
        enableReinitialize
        noValidate
      >
        <AntTable
          {...props}
          columns={mergedColumns as any}
          className={className}
          scroll={{ x: "max-content" }}
        />
      </Form>
    );
  }

  return (
    <AntTable
      {...props}
      columns={mergedColumns as any}
      className={className}
      scroll={{ x: "max-content" }}
    />
  );
}

Table.ColumnGroup = AntTable.ColumnGroup;
Table.Column = Column;

export default Table;
