import { combineReducers } from "redux";
import authModule from "src/redux/modules/auth";
import profileModule from "src/redux/modules/profile";
import categoryModule from "src/redux/modules/categorys";
import categoryBanksModule from "src/redux/modules/categorys/banksCategory";
import categoryCustomerModule from "src/redux/modules/categorys/customerCategory";
import bannerModule from "src/redux/modules/banner";
import dataLookupModule from "src/redux/modules/dataLookup";
import commonCategoriesModule from "src/redux/modules/commonCategories";
import userModule from "src/redux/modules/user";
import roleModule from "src/redux/modules/role";
import logModule from "src/redux/log";
import walletModule from "src/redux/modules/wallet";

export const rootReducer = combineReducers({
  auth: authModule.reducer,
  profile: profileModule.reducer,
  categoryModule: categoryModule.reducer,
  categoryBanksModule: categoryBanksModule.reducer,
  categoryCustomerModule: categoryCustomerModule.reducer,
  bannerModule: bannerModule.reducer,
  dataLookupModule: dataLookupModule.reducer,
  commonCategories: commonCategoriesModule.reducer,
  user: userModule.reducer,
  role: roleModule.reducer,
  log: logModule.reducer,
  wallet: walletModule.reducer,
});
