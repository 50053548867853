const pathMain = "/gianguyen";

const RoutePaths = {
  Auth: {
    Index: "/auth",
    Login: "/auth/login",
    ForgotPassword: "/auth/forgot-password",
    NewPassword: "/auth/new-password",
  },
  Screen: {
    Index: pathMain,
    UserWallet: {
      Index: `${pathMain}/user-wallet`,
    },
    BusinessWallet: {
      Index: `${pathMain}/business-wallet`,
    },
    Role: {
      Index: `${pathMain}/role`,
    },
    BannnerApp: {
      Index: `${pathMain}/banner-app`,
    },
    User: {
      Index: `${pathMain}/user`,
    },
    Account: {
      Index: `${pathMain}/account`,
    },
    LogMobile: {
      Index: `${pathMain}/log-user-mobile`,
    },
    LogApp: {
      Index: `${pathMain}/log-user-app-CMS`,
    },
    Category: {
      Index: `${pathMain}/category`,
      Bank: {
        index: `${pathMain}/category/bank`,
      },
      customerGroup: {
        index: `${pathMain}/category/customer-group`,
      },
      hobbies: {
        index: `${pathMain}/category/hobbies`,
      },
      job: {
        index: `${pathMain}/category/job`,
      },
    },
  },
};

export default RoutePaths;
