import { catchError, map, mergeMap, takeUntil } from "rxjs/operators";
import { ofType } from "redux-observable";
import {
  createReduxObservableModule,
  ReduxObservableModuleEpicProps,
} from "src/core/redux/ReduxObservableModule";
import { of } from "rxjs";
import { Action } from "src/core/models/redux";
import categoryBanks from "src/repositories/categoryBanks";
import {
  CreateCategoryRequest,
  UpdateCategoryProps,
  ListCategoryProps,
} from "src/models/categorys";
import { DetailProps } from "src/models/common";

const categoryModule = createReduxObservableModule(
  {
    listCategoryBanks: {
      epic: ({
        actions$,
        actionTypes,
        actions,
      }: ReduxObservableModuleEpicProps<Action<ListCategoryProps>>) =>
        actions$.pipe(
          ofType(actionTypes.start),
          mergeMap((action) =>
            categoryBanks.listCategoryBanks(action.payload).pipe(
              map((response) => actions.success(response.data)),
              catchError((error) => {
                return of(actions.failed(error));
              }),
              takeUntil(actions$.pipe(ofType(actionTypes.cancelled)))
            )
          )
        ),
    },

    createCategoryBanks: {
      epic: ({
        actions$,
        actionTypes,
        actions,
      }: ReduxObservableModuleEpicProps<Action<CreateCategoryRequest>>) =>
        actions$.pipe(
          ofType(actionTypes.start),
          mergeMap((action) =>
            categoryBanks.createCategoryBanks(action.payload).pipe(
              map((response) => actions.success(response.data)),
              catchError((error) => {
                return of(actions.failed(error));
              }),
              takeUntil(actions$.pipe(ofType(actionTypes.cancelled)))
            )
          )
        ),
    },

    updateCategoryBanks: {
      epic: ({
        actions$,
        actionTypes,
        actions,
      }: ReduxObservableModuleEpicProps<Action<UpdateCategoryProps>>) =>
        actions$.pipe(
          ofType(actionTypes.start),
          mergeMap((action) =>
            categoryBanks.updateCategoryBanks(action.payload).pipe(
              map((response) => actions.success(response.data)),
              catchError((error) => {
                return of(actions.failed(error));
              }),
              takeUntil(actions$.pipe(ofType(actionTypes.cancelled)))
            )
          )
        ),
    },

    detailCategoryBanks: {
      epic: ({
        actions$,
        actionTypes,
        actions,
      }: ReduxObservableModuleEpicProps<Action<DetailProps>>) =>
        actions$.pipe(
          ofType(actionTypes.start),
          mergeMap((action) =>
            categoryBanks.detailCategoryanks(action.payload).pipe(
              map((response) => actions.success(response.data)),
              catchError((error) => {
                return of(actions.failed(error));
              }),
              takeUntil(actions$.pipe(ofType(actionTypes.cancelled)))
            )
          )
        ),
    },

    deleteCategoryBanks: {
      epic: ({
        actions$,
        actionTypes,
        actions,
      }: ReduxObservableModuleEpicProps<Action<DetailProps>>) =>
        actions$.pipe(
          ofType(actionTypes.start),
          mergeMap((action) =>
            categoryBanks.deleteCategoryBanks(action.payload).pipe(
              map((response) => actions.success(response.data)),
              catchError((error) => {
                return of(actions.failed(error));
              }),
              takeUntil(actions$.pipe(ofType(actionTypes.cancelled)))
            )
          )
        ),
    },
  },
  "CATEGORYBANKS"
);

export default categoryModule;
