import { ConfigProvider, message } from "antd";
import { useEffect, useState } from "react";
import { AppRoutes } from "src/routes";
import viAntLocale from "src/localization/languages/vi/antLocale";
import { useDidUpdate, useMount } from "src/core/hooks";
import { i18n } from "src/localization";
import PermissionProvider from "src/core/permission/PermissionProvider";
import useAuth from "src/hooks/modules/useAuth";
import TokenManager from "src/core/utilities/TokenManager";
import { useTranslation } from "react-i18next";
import { Api } from "src/core/api";
import useCommconCategories from "src/hooks/useCommonCategories";

function AppRoot() {
  // Handle locale
  const { t } = useTranslation();
  const [antLocale, setAntLocale] = useState(viAntLocale);

  useMount(() => {
    i18n.on("languageChanged", (lang) => {
      switch (lang) {
        case "vi":
          setAntLocale(viAntLocale);
      }
    });
  });

  // Handle auth state and token
  const { isUser, token, logout } = useAuth();

  useDidUpdate(() => {
    if (isUser) {
      message.success(t("auth:message_login_success"));
      TokenManager.setToken("base_token", token || "");
    } else {
      TokenManager.setToken("base_token", "");
      message.destroy();
    }
  }, [isUser]);

  // Handle api error
  useMount(() => {
    const removeInterceptor = Api.addInterceptor({
      response: {
        success(response) {
          if (response.data.error) {
            if (response.data.errorDescription !== "Tài khoản không tồn tại") {
              // message.error(response.data.error.message);
            }
          }
          return response;
        },

        error(error) {
          if (error.request) {
            if (error.request.status === 401) {
              logout();
              return;
            }

            if (error.request.status === 403) {
              message.destroy();
              message.error("Bạn không có quyền truy cập vào tài nguyên này");
              return;
            }

            if (!error.request.response) {
              return;
            }

            const response = JSON.parse(error.request.response);
            if (response.error && response.error.message) {
              if (
                response.error.message !== "Incorrect password." &&
                response.error.message !==
                  "Tên đăng nhập hoặc mật khẩu không đúng" &&
                response.error.message !==
                  "The user account has been locked out due to invalid login attempts. Please wait a while and try again."
              ) {
                message.error(response.error.message);
              }
            }

            if (response.errors && Array.isArray(response.errors)) {
              message.error(
                t(("errors:server_errors." + response.errors[0]) as any)
              );
            }
          }

          return error;
        },
      },
    });

    return removeInterceptor;
  });

  // Handle initial app data
  const { getAllCommonCategories } = useCommconCategories();
  useEffect(() => {
    if (isUser) {
      getAllCommonCategories();
    }
  }, [isUser]);

  return (
    <ConfigProvider locale={antLocale}>
      <PermissionProvider userPermissions={[]} isUser={true}>
        <AppRoutes />
      </PermissionProvider>
    </ConfigProvider>
  );
}

export default AppRoot;
