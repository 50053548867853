import { lazy } from "react";
import { MenuItem } from "src/models/layout";
import routePaths from "src/routes/paths";

const AuthRoutes = (): MenuItem[] => {
  return [
    {
      path: routePaths.Auth.Index,
      component: lazy(() => import("src/pages/unAuthenticated/login")),
    },
    {
      path: routePaths.Auth.ForgotPassword,
      component: lazy(() => import("src/pages/unAuthenticated/forgotPassword")),
    },
  ];
};

export default AuthRoutes;
