import { useMemo } from "react";
import { Col, Image, Row } from "antd";
import States, { State } from "../States";
import { ColumnTitle } from "antd/lib/table/interface";
import { DataIndex } from "rc-table/lib/interface";
import { TableSortType } from "src/components/Table/CustomFilter";
import Images from "src/assets/images";
import Form from "src/components/Form";

interface TableCustomSortProps {
  title?: ColumnTitle<any>;
  dataIndex?: DataIndex;
}

const TableCustomSort = ({ dataIndex, title }: TableCustomSortProps) => {
  const sortStates = useMemo(
    (): State<TableSortType>[] => [
      {
        value: null,
        icon: <Image src={Images.pages.sort} width={18} preview={false} />,
      },
      {
        value: "ASC",
        icon: (
          <Image src={Images.pages.sortAscend} width={18} preview={false} />
        ),
      },
      {
        value: "DESC",
        icon: (
          <Image src={Images.pages.sortDescend} width={18} preview={false} />
        ),
      },
    ],
    []
  );
  return (
    <Row gutter={[16, 16]} justify="end" align="middle">
      <Col flex={1}>{title}</Col>
      <Col>
        <Form.Item
          name={[
            "sort",
            ...(Array.isArray(dataIndex)
              ? dataIndex
              : typeof dataIndex === "string" || typeof dataIndex === "number"
              ? [dataIndex]
              : []),
          ]}
          initialValue={null}
          noStyle
        >
          <States states={sortStates} />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default TableCustomSort;
