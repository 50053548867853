import { TableColumnProps } from "./Column";
import TableCustomFilter from "./CustomFilter";
import TableCustomSort from "./CustomSort";

export function transformColumns<RecordType = any>(
  columns: TableColumnProps<RecordType>[]
) {
  const hasColumnWithCustomFilter = columns.some(
    (col) =>
      col.customFilter !== undefined &&
      (col.customFilter.customInput || col.customFilter?.filters)
  );

  const result: TableColumnProps<RecordType>[] = columns.map(
    ({ children, ...column }) => {
      return {
        ...column,
        title: column.customFilter?.sort ? (
          <TableCustomSort title={column.title} dataIndex={column.dataIndex} />
        ) : (
          column.title
        ),
        children: column.customFilter
          ? [
              {
                ...column,
                onHeaderCell: () => {
                  return {
                    className: "py-2 filter-cell",
                  };
                },
                title: (
                  <TableCustomFilter
                    {...column.customFilter}
                    dataIndex={column.dataIndex}
                  />
                ),
              },
            ]
          : children || hasColumnWithCustomFilter
          ? [
              {
                ...column,
                title: undefined,
                onHeaderCell: () => {
                  return {
                    className: "py-2 filter-cell",
                  };
                },
              },
            ]
          : undefined,
      };
    }
  );
  return result;
}
