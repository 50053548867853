import env from "src/core/env";
import { Repository } from "src/core/Repository";
import {
  GetAllPermissionsResponse,
  ListRoleRequest,
  ListRoleResponse,
  SingleRoleRequest,
} from "src/models/Role";

class RoleRepository extends Repository<
  SingleRoleRequest,
  undefined,
  ListRoleRequest,
  ListRoleResponse
> {
  protected basePath = "auth/admin";

  constructor() {
    super(env.API_AUTH_URL);
  }

  getAllPermissions() {
    return this.request<GetAllPermissionsResponse>({
      url: "/getAllPermissions",
      method: "GET",
    });
  }

  get(data: ListRoleRequest) {
    return this.request<ListRoleResponse>({
      url: "/getAllRoles",
      method: "POST",
      data,
    });
  }

  create(data: SingleRoleRequest) {
    return this.request<undefined>({
      url: "/createRole",
      method: "POST",
      data,
    });
  }

  updateOne(data: SingleRoleRequest) {
    return this.request<undefined>({
      url: "/updateRole",
      method: "POST",
      data,
    });
  }

  delete(id: number) {
    return this.request<undefined>({
      url: "/deleteRole/" + id,
      method: "PUT",
    });
  }
}

export default new RoleRepository();
