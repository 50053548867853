import env from "src/core/env";
import { Repository } from "src/core/Repository";
import {
  CreateCategoryRequest,
  UpdateCategoryProps,
  ListCategoryProps,
  ListCategoryResponse,
} from "src/models/categorys";
import { DetailProps } from "src/models/common";

class CategoryCustomer extends Repository {
  protected basePath = "/customerGroups/";

  constructor() {
    super(env.API_URL);
  }

  listCategoryCustomer(params: ListCategoryProps) {
    return this.request<ListCategoryResponse>({
      method: "POST",
      url: "getAll",
      data: params,
    });
  }

  getDetailCategoryCustomer(param: DetailProps) {
    return this.request<null>({
      method: "GET",
      url: `getByIdForApp/${param.id}`,
    });
  }

  createCategoryCustomer(params: CreateCategoryRequest) {
    return this.request({
      method: "POST",
      url: "create",
      data: params,
    });
  }

  updateCategoryCustomer(params: UpdateCategoryProps) {
    return this.request({
      method: "POST",
      url: "update",
      data: params,
    });
  }

  deleteCategoryCustomer(param: DetailProps) {
    return this.request({
      method: "POST",
      url: `delete/${param.id}`,
      data: param,
    });
  }
}
export default new CategoryCustomer();
