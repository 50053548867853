import { Layout, PageHeader, Spin, Menu } from "antd";
import React, {
  FunctionComponent,
  Suspense,
  useCallback,
  useLayoutEffect,
  useState,
  createContext,
} from "react";
import { useHistory, Link } from "react-router-dom";

import env from "src/core/env";
import { Listener } from "src/core/utilities/EventListenersManager";
import UserProfileDrawer from "src/layouts/Dashboard/components/Header";
import { MenuItem } from "src/models/layout";
import LayoutEventsListenersManager, {
  LayoutEvents,
} from "src/layouts/LayoutEventsListenersManager";
import { LeftOutlined } from "@ant-design/icons";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useToggle } from "src/core/hooks";
import useCurrentRoute from "src/hooks/useCurrentRoute";
import Images from "src/assets/images";
const { Content, Sider } = Layout;

interface DashboardLayoutProps {
  routes: MenuItem[];
}
interface DashboardLayoutProps {
  defaultConfig?: LayoutConfig;
  routes: MenuItem[];
  bottomRoutes: MenuItem[];
}

export interface LayoutConfig {
  showPageTitle?: boolean;
  showHeaderExtra?: boolean;
  headerExtra?: React.ReactNode;
  backButton?: string | boolean;
  showSidebar?: boolean;
  pageTitle?: string;
}

export type LayoutConfigContextType = {
  config: LayoutConfig;
  addEventListener<E extends keyof LayoutEvents>(
    event: E,
    listener: Listener<LayoutEvents[E]>
  ): () => void;
  changeLayoutConfig: (config: LayoutConfig) => void;
};

export const LayoutConfigContext = createContext<
  LayoutConfigContextType | undefined
>(undefined);

const defaultLayoutConfig: LayoutConfig = {
  showPageTitle: true,
  showHeaderExtra: true,
  headerExtra: undefined,
  showSidebar: true,
  backButton: false,
  pageTitle: "",
};

const DashboardLayout: FunctionComponent<DashboardLayoutProps> = ({
  defaultConfig = defaultLayoutConfig,
  children,
  routes,
}) => {
  // const history = useHistory();
  // const { location } = useHistory();
  // const [config, setConfig] = useState<LayoutConfig>(defaultConfig);
  // Handle page title and breadcrumb
  // const [collapsed, setCollapsed] = useState(false);
  // const currentRoute = useCurrentRoute(routes);
  // useEffect(() => {
  //   if (currentRoute) {
  //     document.title =
  //       currentRoute.documentTitle ||
  //       currentRoute.pageTitle ||
  //       currentRoute.name ||
  //       env.APP_NAME;
  //   }
  // }, [currentRoute]);

  // // Handle menu list
  // const [subRoutes, setSubRoutes] = useState<MenuItem[]>([]);

  // const handleMainMenuItemChange = useCallback((item: MenuItem) => {
  //   setSubRoutes(item.routes || []);
  // }, []);
  // // End handle menu list

  // // Handle first menu scrollbar
  // const [firstMenuHeight, setFirstMenuHeight] = useState(0);
  // const firstSiderBottomContainer = createRef<HTMLDivElement>();
  // const firstSiderRef = createRef<HTMLDivElement>();
  // const { SubMenu } = Menu;

  // useLayoutEffect(() => {
  //   setFirstMenuHeight(
  //     window.innerHeight -
  //       150 -
  //       (firstSiderBottomContainer.current?.clientHeight || 0)
  //   );
  // });

  // useMount(() => {
  //   const listener = () => {
  //     setFirstMenuHeight(
  //       window.innerHeight -
  //         150 -
  //         (firstSiderBottomContainer.current?.clientHeight || 0)
  //     );
  //   };

  //   window.addEventListener("resize", listener);

  //   return () => window.removeEventListener("resize", listener);
  // });

  // Handle second sider collapse
  // const {
  //   state: secondSiderCollapsed,
  //   on: collapseSecondSider,
  //   off: expandSecondSider,
  //   toggle: toggleSeconSiderCollapse,
  // } = useToggle();

  // const handleSecondSiderBreak = useCallback((breaked: boolean) => {
  //   if (breaked) {
  //     collapseSecondSider();
  //   } else {
  //     expandSecondSider();
  //   }
  // }, []);

  // const onCollapse = () => {
  //   setCollapsed(!collapsed);
  // };
  // const changeLayoutConfig = useCallback((config: LayoutConfig) => {
  //   setConfig((old) => ({
  //     ...old,
  //     ...config,
  //   }));
  // }, []);

  // const currentRoute = useCurrentRoute(routes);
  // useLayoutEffect(() => {
  //   if (currentRoute) {
  //     document.title =
  //       currentRoute.location?.key ||
  //       currentRoute.documentTitle ||
  //       currentRoute.pageTitle ||
  //       currentRoute.name ||
  //       env.APP_NAME;

  //     if (!config.pageTitle) {
  //       changeLayoutConfig({
  //         pageTitle: currentRoute.pageTitle || currentRoute.name,
  //       });
  //     }
  //   }
  // }, [currentRoute]);

  // const handleBackButtonClick = useCallback(() => {
  //   if (config.backButton !== false) {
  //     typeof config.backButton === "string" && config.backButton.length > 0
  //       ? history.push(config.backButton)
  //       : history.goBack();
  //   }
  // }, [config.backButton]);

  const { location } = useHistory();
  const [config, setConfig] = useState<LayoutConfig>(defaultConfig);

  const changeLayoutConfig = useCallback((config: LayoutConfig) => {
    setConfig((old) => ({
      ...old,
      ...config,
    }));
  }, []);

  const addEventListener = useCallback(function <E extends keyof LayoutEvents>(
    event: E,
    listener: Listener<LayoutEvents[E]>
  ) {
    return LayoutEventsListenersManager.on(event, listener);
  },
  []);

  useLayoutEffect(() => {
    setConfig(defaultConfig);
  }, [location.pathname]);

  // Handle page title and breadcrumb
  const currentRoute = useCurrentRoute(routes);
  useLayoutEffect(() => {
    if (currentRoute) {
      document.title =
        currentRoute.location?.key ||
        currentRoute.documentTitle ||
        currentRoute.pageTitle ||
        currentRoute.name ||
        env.APP_NAME;

      if (!config.pageTitle) {
        changeLayoutConfig({
          pageTitle: currentRoute.pageTitle || currentRoute.name,
        });
      }
    }
  }, [currentRoute]);

  // const fetchAllFields = async (): Promise<any> => {
  //   const response = await administrationRepository.getProfile().toPromise();
  //   setdata(response.data);
  // };

  // Handle second sider collapse
  const {
    state: secondSiderCollapsed,
    on: collapseSecondSider,
    off: expandSecondSider,
    toggle: toggleSeconSiderCollapse,
  } = useToggle();

  const handleSecondSiderBreak = useCallback((breaked: boolean) => {
    if (breaked) {
      collapseSecondSider();
    } else {
      expandSecondSider();
    }
  }, []);

  // Handle back button click
  const history = useHistory();
  const handleBackButtonClick = useCallback(() => {
    if (config.backButton !== false) {
      typeof config.backButton === "string" && config.backButton.length > 0
        ? history.push(config.backButton)
        : history.goBack();
    }
  }, [config.backButton]);

  const renderMenuItem = useCallback((item: any) => {
    return (
      <React.Fragment key={item.path}>
        {!(typeof item.hideChildrenInMenu === "undefined"
          ? true
          : item.hideChildrenInMenu) && item.routes ? (
          <Menu.SubMenu key={item.path} title={item.name} icon={item.icon}>
            {item.routes?.map((childItem: any) => {
              return renderMenuItem(childItem);
            })}
          </Menu.SubMenu>
        ) : (
            typeof item.hideInMenu === "undefined" ? false : item.hideInMenu
          ) ? null : (
          <Menu.Item key={item.path} title={item.name} icon={item.icon}>
            <Link to={item.path}>{item.name}</Link>
          </Menu.Item>
        )}
      </React.Fragment>
    );
  }, []);

  return (
    <LayoutConfigContext.Provider
      value={{
        config,
        changeLayoutConfig,
        addEventListener,
      }}
    >
      <PerfectScrollbar
        id="main-perfect-scroll"
        options={{
          wheelSpeed: 1,
          suppressScrollX: true,
        }}
        style={{ maxHeight: "100vh" }}
      >
        <Layout id="dashboard-layout">
          <Sider
            breakpoint="lg"
            collapsedWidth="0"
            onBreakpoint={(broken) => {
              console.log(broken);
            }}
            onCollapse={(collapsed, type) => {
              console.log(collapsed, type);
            }}
          >
            <div className="logo">
              <img
                src={Images.menu.logo}
                style={{
                  maxWidth: 90,
                  margin: "0 auto",
                }}
              />
            </div>
            <Menu theme="dark" defaultSelectedKeys={["1"]} mode="inline">
              {routes.map((item) => renderMenuItem(item))}
            </Menu>
          </Sider>
          <Layout>
            <UserProfileDrawer />
            <Content>
              <div className="container">
                <div className="sub-container">
                  <Suspense
                    fallback={
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{ width: "100%", height: "100%" }}
                      >
                        <Spin spinning />
                      </div>
                    }
                  >
                    {!!currentRoute && (
                      <div className="page-header-container">
                        <div className="container">
                          <PageHeader
                            backIcon={
                              <LeftOutlined
                                className="p-2 border rounded"
                                style={{ color: "#ddd" }}
                              />
                            }
                            onBack={
                              config.backButton !== false
                                ? handleBackButtonClick
                                : undefined
                            }
                            title={
                              config.showPageTitle
                                ? config.pageTitle
                                : undefined
                            }
                            extra={config.showHeaderExtra && config.headerExtra}
                            className="py-0 ml-5"
                          >
                            {/* {config.showHeaderExtra && config.headerExtra} */}
                          </PageHeader>
                        </div>
                      </div>
                    )}
                    <Content className="pt-1">{children}</Content>
                  </Suspense>
                </div>
              </div>
            </Content>
          </Layout>
        </Layout>
      </PerfectScrollbar>
    </LayoutConfigContext.Provider>
  );
};

export default DashboardLayout;
