import React, {
  FunctionComponent,
  useState,
  useEffect,
  useCallback,
} from "react";

export type PageTitleContextType = {
  pageTitle: string;
  changePageTitle: (title: string) => void;
  backButton: boolean;
  changeBackButton: (title: boolean) => void;
};

export const PageTitleContext = React.createContext<
  PageTitleContextType | undefined
>(undefined);

export const PageTitleProvider: FunctionComponent = ({ children }) => {
  const [pageTitle, setPageTitle] = useState<string>("");
  const [backButton, changeBackButton] = useState<boolean>(false);

  const changePageTitle = useCallback((title: string) => {
    setPageTitle(title);
  }, []);

  useEffect(() => {
    document.title = pageTitle;
  }, [pageTitle]);

  return (
    <PageTitleContext.Provider
      value={{ pageTitle, changePageTitle, backButton, changeBackButton }}
    >
      {children}
    </PageTitleContext.Provider>
  );
};
