import { ListResponse } from "src/models/Base";

export const numberQuery = {
  ALL: -1,
  UN_LOCK: 0,
  LOCK: 1,
  LAST_UPDATE_ASCEND: 1,
  LAST_UPDATE_DESCEND: 2,
  SORT_ASCEND: 3,
  SORT_DESCEND: 4,
};

export enum CategoryType {
  Job = 1,
  Hobby = 2,
  DenyReason = 3,
}

export interface Category {
  id: string;
  code: string;
  title: string;
  titleSearch: string;
  note: null | string;
  priority: number;
  isDefault: boolean;
  status: boolean;
  lastModificationTime: string;
  lastModifierId: null | string;
  type: CategoryType;
}

interface FilrterQuery {
  codeFilter?: string;
  titleFilter?: string;
  statusFilter?: number | undefined;
  typeFilter?: number;
}
interface Query {
  orderBy?: number;
  filterQuery?: FilrterQuery;
}
export interface CreateCategoryRequest {
  code?: string;
  title?: string;
  note?: string;
  priority?: number;
  type?: number;
  isDefault?: boolean;
  status?: boolean | string;
  close?: boolean;
  open?: boolean;
}

export interface UpdateCategoryProps {
  id?: string;
  title?: string;
  note?: string;
  priority?: number;
  type?: number;
  isDefault?: boolean;
  status?: boolean | string;
}

export interface ListCategoryProps {
  pageLength?: number | string;
  page?: number | string;
  query?: Query;
  orderBy?: number;
}

export type ListCategoryResponse = ListResponse<Category>;
