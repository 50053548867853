import env from "src/core/env";
import { Repository } from "src/core/Repository";
import {
  ForgotPasswordSmsRequest,
  ForgotPasswordVerifyRequest,
  LoginRequest,
  LoginResponse,
} from "src/models/auth/Auth";

class AuthRepository extends Repository {
  protected basePath = "auth/";

  constructor() {
    super(env.API_AUTH_URL);
  }

  login(account: LoginRequest) {
    return this.request<LoginResponse>({
      method: "POST",
      url: "loginWithPhoneNumber",
      data: account,
    });
  }

  forgotPasswordSms(params: ForgotPasswordSmsRequest) {
    console.log("sms", params);
    return this.request({
      method: "POST",
      url: "sendPasswordRecoverSMS",
      data: params,
    });
  }

  forgotPasswordVerify(params: ForgotPasswordVerifyRequest) {
    return this.request({
      method: "POST",
      url: "resetPasswordWithPhone",
      data: params,
    });
  }
}

export default new AuthRepository();
