import { memo, useRef } from "react";
import { connect } from "react-redux";
import { RootReducer } from "src/redux";
import RouterGenerator from "src/core/routes/RouterGenerator";
import userRoutes from "src/routes/listRoutes";
import { AuthState } from "src/models/auth/AuthState";
import authRoutes from "src/routes/authRoutes";
import routePaths from "src/routes/paths";

interface AppRoutesProps {
  authState: AuthState;
}

const mapStateToProps = (state: RootReducer) => {
  return {
    authState: state.auth.state as AuthState,
  };
};

const mapDispatchToProps = {};
const AppRoutesBased = ({ authState }: AppRoutesProps) => {
  const guestRoutes = useRef(authRoutes()).current;
  const userRoute = useRef(userRoutes()).current;
  return (
    <RouterGenerator
      guestRoutes={guestRoutes}
      userRoutes={userRoute}
      isUser={authState === AuthState.loggedIn}
      initialGuestRoutePath={routePaths.Auth.Index}
      initialUserRoutePath={routePaths.Screen.Index}
      initialRoutePath={routePaths.Auth.Index}
    />
  );
};

export const AppRoutes = connect(
  mapStateToProps,
  mapDispatchToProps
)(memo(AppRoutesBased));
