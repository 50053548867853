import { Spin } from "antd";
import { Suspense } from "react";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import AppRoot from "src/AppRoot";
import { PageTitleProvider } from "src/core/providers/PageTitleProvider";
import { i18n } from "src/localization";
import store, { persistor } from "src/redux";
import "src/styles/themes/default.less";
import "react-perfect-scrollbar/dist/css/styles.css";
import ErrorBoundary from "src/ErrorBoundary";
import { LoadingProvider } from "src/core/providers/LoadingProvider";

export default function App() {
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <I18nextProvider i18n={i18n}>
            <PageTitleProvider>
              <Suspense
                fallback={
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ width: "100vw", height: "100vh" }}
                  >
                    <Spin spinning />
                  </div>
                }
              >
                <LoadingProvider>
                  <AppRoot />
                </LoadingProvider>
              </Suspense>
            </PageTitleProvider>
          </I18nextProvider>
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  );
}
