import env from "src/core/env";
import { Repository } from "src/core/Repository";
import { GetListBannerRequest, creacteBannerProps } from "src/models/banner";
import { DetailProps } from "src/models/common";

class BannerRespository extends Repository {
  protected basePath = "/news/";

  constructor() {
    super(env.API_URL);
  }

  listBanner(params: GetListBannerRequest) {
    return this.request({
      method: "POST",
      url: "getAll",
      data: params,
    });
  }

  createBanner(params: creacteBannerProps) {
    return this.request({
      method: "POST",
      url: "create",
      data: params,
    });
  }

  updateBanner(params: creacteBannerProps) {
    return this.request({
      method: "POST",
      url: "update",
      data: params,
    });
  }

  detailBanner(param: DetailProps) {
    return this.request<null>({
      method: "GET",
      url: `getByIdForApp/${param.id}`,
    });
  }

  deleteBanner(param: DetailProps) {
    return this.request({
      method: "POST",
      url: `delete/${param.id}`,
      data: param,
    });
  }
}
export default new BannerRespository();
