import { ColumnProps } from "antd/lib/table";
import { TableCustomFilterProps } from "./CustomFilter";

export interface TableColumnProps<RecordType>
  extends Omit<ColumnProps<RecordType>, "children"> {
  customFilter?: TableCustomFilterProps;
  children?: TableColumnProps<RecordType>[];
}

export function Column<RecordType>(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _: Omit<TableColumnProps<RecordType>, "children">
) {
  return null;
}
