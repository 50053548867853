import env from "src/core/env";
import { Repository } from "src/core/Repository";

class ProfileRespository extends Repository {
  protected basePath = "api/";

  constructor() {
    super(env.API_URL);
  }

  getProfile() {
    return this.request<null>({
      method: "GET",
      url: "cms-service/archives",
    });
  }

  changePassword(params: any) {
    return this.request({
      method: "POST",
      url: "",
      data: params,
    });
  }
}
export default new ProfileRespository();
