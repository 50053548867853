import { RefObject, useCallback, useEffect, useMemo, useState } from "react";

export function useScroll(
  inputElement: HTMLElement | RefObject<HTMLElement> | string = document.body
) {
  const [scrollTop, setScrollTop] = useState(0);

  const element = useMemo(
    () =>
      inputElement instanceof HTMLElement
        ? inputElement
        : typeof inputElement === "string"
        ? document.getElementById(inputElement)
        : inputElement.current,
    [inputElement]
  );

  const scrollTo = useCallback(
    (top: number) => {
      element?.scrollTo({
        top,
        behavior: "smooth",
      });
    },
    [element]
  );

  const scrollToTop = useCallback(() => {
    scrollTo(0);
  }, [scrollTo]);

  useEffect(() => {
    function scrollListener() {
      setScrollTop(element?.scrollTop || 0);
    }

    element?.addEventListener("scroll", scrollListener);

    return () => {
      element?.removeEventListener("scroll", scrollListener);
    };
  }, [element]);

  return {
    scrollTop,
    scrollTo,
    scrollToTop,
  };
}
