import { FC, memo } from "react";
import { Modal as RModal, ModalProps, Space } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import Button from "../Button";
import { ModalTypes } from "src/models/Modal";

interface CustomModalProps extends ModalProps {
  handleCancel?: () => void;
  handleSubmit?: () => void;
  type?: ModalTypes;
  children?: React.ReactNode;
  isDisaledButton?: boolean;
}

const CustomModal: FC<CustomModalProps> = (props) => {
  const {
    isDisaledButton,
    handleCancel,
    handleSubmit,
    type,
    children,
    ...rest
  } = props;
  const { t } = useTranslation();
  return (
    <RModal
      onCancel={handleCancel}
      closeIcon={
        <Button
          icon={<CloseOutlined style={{ color: "var(--primary-color)" }} />}
          className="border-0"
          style={{ backgroundColor: "rgba(0, 117, 160, 0.2)" }}
        />
      }
      footer={
        type !== ModalTypes.Read && (
          <Space>
            <Button
              onClick={handleCancel}
              className="px-5"
              style={{
                borderColor: "var(--primary-color)",
                color: "var(--primary-color)",
              }}
            >
              {t("actions:cancel")}
            </Button>
            <Button
              onClick={handleSubmit}
              disabled={isDisaledButton}
              htmlType="submit"
              type="primary"
              className="px-5 border-0"
              style={{ backgroundColor: "var(--primary-color)" }}
            >
              {t("actions:save")}
            </Button>
          </Space>
        )
      }
      className="modal-customize"
      destroyOnClose
      {...rest}
    >
      {children}
    </RModal>
  );
};

export default memo(CustomModal);
