import { useContext, useEffect } from "react";
import {
  LoadingContext,
  LoadingContextType,
} from "src/core/providers/LoadingProvider";

export const useLoading = (state?: boolean) => {
  const context = useContext<LoadingContextType | undefined>(LoadingContext);

  if (context === undefined) {
    throw new Error("useLoading must be used in LoadingProvider");
  }

  useEffect(() => {
    if (typeof state === "undefined") {
      return;
    }

    if (state) {
      context.startLoading();
    } else {
      context.stopLoading();
    }
  }, [state, context]);

  return context;
};
