import React, { useCallback, useEffect, useMemo, useState } from "react";

export interface State<ValueType> {
  label?: string;
  value: ValueType;
  icon: React.ReactNode;
}

interface StatesProps<ValueType = any> {
  states: State<ValueType>[];
  value?: ValueType;
  defaultValue?: ValueType;
  onChange?: (value?: ValueType, state?: State<ValueType>) => void;
  allowClear?: boolean;
}

const States = function <ValueType = any>({
  states,
  defaultValue,
  value,
  onChange,
  allowClear,
}: StatesProps<ValueType>) {
  const [currentValue, setCurrentValue] = useState<ValueType | undefined>(
    defaultValue || value
  );

  const currentStateIndex = useMemo(
    () => states.findIndex((state) => state.value === currentValue),
    [currentValue]
  );

  const currentState = useMemo(
    (): State<ValueType> | undefined =>
      currentStateIndex > -1 ? states[currentStateIndex] : undefined,
    [currentStateIndex]
  );

  const nextState = useCallback(() => {
    let nextIndex = currentStateIndex + 1;

    if (nextIndex === states.length) {
      if (allowClear) {
        setCurrentValue(undefined);
        onChange && onChange(undefined, undefined);
        return;
      }

      nextIndex = 0;
    }

    const nextState = states[nextIndex];

    onChange && onChange(nextState.value, nextState);
    setCurrentValue(nextState.value);
  }, [allowClear, currentStateIndex, states]);

  useEffect(() => {
    if (value !== currentValue) {
      setCurrentValue(value);
    }
  }, [value]);

  return (
    <span onClick={nextState} className="pointer">
      {currentState && currentState.icon}
    </span>
  );
};

export default States;
