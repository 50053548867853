import env from "src/core/env";
import { Repository } from "src/core/Repository";
import { GetLookup } from "src/models/lookup";

class LookupRespository extends Repository {
  protected basePath = "/commonData/";

  constructor() {
    super(env.API_URL);
  }

  listTypeLookup(params: GetLookup) {
    return this.request({
      method: "POST",
      url: "getNewsTypeLookup",
      data: params,
    });
  }

  customerGroupLookup(params: GetLookup) {
    return this.request({
      method: "POST",
      url: "getCustomerGroupsLookup",
      data: params,
    });
  }
}
export default new LookupRespository();
