import { catchError, map, mergeMap, takeUntil } from "rxjs/operators";
import { ofType } from "redux-observable";
import {
  createReduxObservableModule,
  ReduxObservableModuleEpicProps,
} from "src/core/redux/ReduxObservableModule";
import { of } from "rxjs";
import { Action } from "src/core/models/redux";
import categoryCommon from "src/repositories/categoryCommon";
import {
  CreateCategoryRequest,
  UpdateCategoryProps,
  ListCategoryProps,
} from "src/models/categorys";
import { DetailProps } from "src/models/common";

const categoryModule = createReduxObservableModule(
  {
    listCategory: {
      epic: ({
        actions$,
        actionTypes,
        actions,
      }: ReduxObservableModuleEpicProps<Action<ListCategoryProps>>) =>
        actions$.pipe(
          ofType(actionTypes.start),
          mergeMap((action) =>
            categoryCommon.listCategory(action.payload).pipe(
              map((response) => actions.success(response.data)),
              catchError((error) => {
                return of(actions.failed(error));
              }),
              takeUntil(actions$.pipe(ofType(actionTypes.cancelled)))
            )
          )
        ),
    },

    createCategory: {
      epic: ({
        actions$,
        actionTypes,
        actions,
      }: ReduxObservableModuleEpicProps<Action<CreateCategoryRequest>>) =>
        actions$.pipe(
          ofType(actionTypes.start),
          mergeMap((action) =>
            categoryCommon.createCategory(action.payload).pipe(
              map((response) => actions.success(response.data)),
              catchError((error) => {
                return of(actions.failed(error));
              }),
              takeUntil(actions$.pipe(ofType(actionTypes.cancelled)))
            )
          )
        ),
    },

    getDetailCategory: {
      epic: ({
        actions$,
        actionTypes,
        actions,
      }: ReduxObservableModuleEpicProps<Action<DetailProps>>) =>
        actions$.pipe(
          ofType(actionTypes.start),
          mergeMap((action) =>
            categoryCommon.getDetailCategory(action.payload).pipe(
              map((response) => actions.success(response.data)),
              catchError((error) => {
                return of(actions.failed(error));
              }),
              takeUntil(actions$.pipe(ofType(actionTypes.cancelled)))
            )
          )
        ),
    },

    updateCategory: {
      epic: ({
        actions$,
        actionTypes,
        actions,
      }: ReduxObservableModuleEpicProps<Action<UpdateCategoryProps>>) =>
        actions$.pipe(
          ofType(actionTypes.start),
          mergeMap((action) =>
            categoryCommon.updateCategory(action.payload).pipe(
              map((response) => actions.success(response.data)),
              catchError((error) => {
                return of(actions.failed(error));
              }),
              takeUntil(actions$.pipe(ofType(actionTypes.cancelled)))
            )
          )
        ),
    },

    deleteCategory: {
      epic: ({
        actions$,
        actionTypes,
        actions,
      }: ReduxObservableModuleEpicProps<Action<DetailProps>>) =>
        actions$.pipe(
          ofType(actionTypes.start),
          mergeMap((action) =>
            categoryCommon.deleteCategory(action.payload).pipe(
              map((response) => actions.success(response.data)),
              catchError((error) => {
                return of(actions.failed(error));
              }),
              takeUntil(actions$.pipe(ofType(actionTypes.cancelled)))
            )
          )
        ),
    },
    // deleteTableRowCategory: {
    //   epic: ({
    //     actions$,
    //     actionTypes,
    //     actions,
    //   }: ReduxObservableModuleEpicProps<Action<DeleteRequest>>) =>
    //     actions$.pipe(
    //       ofType(actionTypes.start),
    //       mergeMap((action) =>
    //         directorysRepository
    //           .deleteTableRowCategoryType(action.payload)
    //           .pipe(
    //             map((response) => actions.success(response.data)),
    //             catchError((error) => {
    //               return of(actions.failed(error));
    //             }),
    //             takeUntil(actions$.pipe(ofType(actionTypes.cancelled)))
    //           )
    //       )
    //     ),
    // },
    // editTableRowCategory: {
    //   epic: ({
    //     actions$,
    //     actionTypes,
    //     actions,
    //   }: ReduxObservableModuleEpicProps<Action<CategoryListRequest>>) =>
    //     actions$.pipe(
    //       ofType(actionTypes.start),
    //       mergeMap((action) =>
    //         directorysRepository.editTableRowCategoryType(action.payload).pipe(
    //           map((response) => actions.success(response.data)),
    //           catchError((error) => {
    //             return of(actions.failed(error));
    //           }),
    //           takeUntil(actions$.pipe(ofType(actionTypes.cancelled)))
    //         )
    //       )
    //     ),
    // },
  },
  "CATEGORY"
);

export default categoryModule;
