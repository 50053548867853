import { ofType } from "redux-observable";
import { of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { Action } from "src/core/models/redux";
import {
  createReduxObservableModule,
  ReduxObservableModuleEpicProps,
} from "src/core/redux/ReduxObservableModule";
import { ListUserWalletRequest } from "src/models/Wallet";
import WalletRepository from "src/repositories/WalletRepository";

const walletModule = createReduxObservableModule(
  {
    getAllUserWallet: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<ListUserWalletRequest>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          WalletRepository.getAllUserWallet(action.payload).pipe(
            map((response) => actions.success(response.data)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),
    getWalletBalanceHistory: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<string>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          WalletRepository.getWalletBalanceHistory(action.payload).pipe(
            map((response) => actions.success(response.data)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),
  },
  "wallet"
);

export default walletModule;
