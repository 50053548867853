import env from "src/core/env";
import { Repository } from "src/core/Repository";
import {
  CreateCategoryRequest,
  UpdateCategoryProps,
  ListCategoryProps,
  ListCategoryResponse,
} from "src/models/categorys";
import { DetailProps } from "src/models/common";

class CategoryBanksRespository extends Repository {
  protected basePath = "/banks/";

  constructor() {
    super(env.API_URL);
  }

  listCategoryBanks(params: ListCategoryProps) {
    return this.request<ListCategoryResponse>({
      method: "POST",
      url: "getAll",
      data: params,
    });
  }

  createCategoryBanks(params: CreateCategoryRequest) {
    return this.request({
      method: "POST",
      url: "create",
      data: params,
    });
  }

  updateCategoryBanks(params: UpdateCategoryProps) {
    return this.request({
      method: "POST",
      url: "update",
      data: params,
    });
  }

  detailCategoryanks(param: DetailProps) {
    return this.request<null>({
      method: "GET",
      url: `getByIdForApp/${param.id}`,
    });
  }

  deleteCategoryBanks(param: DetailProps) {
    return this.request({
      method: "POST",
      url: `delete/${param.id}`,
      data: param,
    });
  }
}
export default new CategoryBanksRespository();
